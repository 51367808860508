import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = `${environment.apiEndPoint}/Payment`;
  }

  getAllPayments(req: any): Observable<object> {

    return this.http.post(`${this.baseUrl}/Payment/GetAllPayment`, req);
  }

  getIsTenantCardPaymentEnabled() : Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/Cube/IsTenantCardPaymentEnabled`, );
  }

}
