import {ChangeDetectorRef, Component} from '@angular/core';
import {AuthTokenService, CommonService, MainDataService, PermissionService, StoreService} from "@core/services";
import {CookieService} from "ngx-cookie-service";
import {ActivatedRoute, Router} from "@angular/router";
import {GaLocalActionEnum} from "@core/enums/ga-local-action-enum";
import {GoogleAnalyticsService} from "ngx-google-analytics";

interface Menu {
  name: string,
  icon: string,
  filledIcon: string
}

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss']
})
export class MobileNavbarComponent {
  authToken: any = '';
  floatingMenu: Menu[];
  cartListCount: any = 0;
  isMobileTemplate: boolean = false;
  isGoogleAnalytics: boolean = false;
  selectedMenu : Menu | null;
  userDetails: any;
  constructor(private authTokenService: AuthTokenService,
              private cookieService: CookieService,
              protected router: Router,
              private mainDataService: MainDataService,
              private cd: ChangeDetectorRef,
              private commonService : CommonService,
              private $gaService: GoogleAnalyticsService,
              private permissionService: PermissionService ,
              private store: StoreService ) {
    this.isMobileTemplate = this.permissionService.hasPermission('Mobile-Layout');
    this.isGoogleAnalytics = this.permissionService.hasPermission('Google Analytics');

  }

  ngOnInit() {
    this.userDetails = this.store.get('profile');
    this.mainDataService.getCartLengthData().subscribe((data: any) => {

      this.cartListCount = data ?? 0;
      if (this.cartListCount > 0) {

        if (this.cartListCount > 9) {
          this.cartListCount = '9+';
        }
      }
      this.cd.markForCheck();
      this.cd.detectChanges();
    });
    this.floatingMenu = [
      {
        name: 'navbar.home',
        icon: 'assets/icons/mobile-home.svg',
        filledIcon: 'assets/icons/mobile-home-filled.svg'
      },
      {
        name: 'navbar.Wishlist',
        icon: 'assets/icons/mobile-wishlist.svg',
        filledIcon: 'assets/icons/mobile-wishlist-filled.svg'
      },
      {
        name: 'navbar.Categories',
        icon: 'assets/icons/mobile-categories.svg',
        filledIcon: 'assets/icons/mobile-categories-filled.svg'
      },
      {
        name: 'navbar.Cart',
        icon: 'assets/icons/mobile-cart.svg',

        filledIcon: 'assets/icons/mobile-cart-flled.svg'
      },
      {
        name: 'navbar.Profile',
        icon: 'assets/icons/mobile-profile.svg',
        filledIcon: 'assets/icons/mobile-profile-filled.svg'
      }
    ]
    this.selectedMenu = this.floatingMenu[0];
    this.commonService.isShowSearchBox.next(true);
    this.authTokenService.authTokenData.subscribe(
      (message) => (this.authToken = message)
    );
    if (!this.authToken) {
      this.authToken = this.cookieService.get('authToken');
    }
  }

  navigateFloating(menu: Menu) {

    const tempSelectedMenu: Menu | null = this.floatingMenu.find((m: Menu) => m.name === menu.name) ?? null

    if (menu.name === 'navbar.Profile') {
      if(this.isGoogleAnalytics){
      this.$gaService.event(GaLocalActionEnum.CLICK_ON_SIGN_IN_UP, '', 'SIGNIN/SIGNUP', 1, true , {
        "user_ID":this.userDetails?this.userDetails?.mobileNumber:'Un_Authenticated',
        "ip_Address": this.store.get('userIP'),
        "device_Type": this.store.get('deviceInfo')?.deviceType,
       "device_Id": this.store.get('deviceInfo')?.deviceId,
      });
    }
      if (this.authToken) this.router.navigate(['/account'])
      else this.router.navigate(['/login'])
    } else if(menu.name === 'navbar.home') {
      this.router.navigate(['/'])
    } else if(menu.name === 'navbar.Wishlist') {
      if(this.isGoogleAnalytics){

      this.$gaService.event(GaLocalActionEnum.CLICK_ON_WISHLIST_FOOTER, '', '', 1, true,{
        "user_ID":this.userDetails?this.userDetails.mobileNumber:'Un_Authenticated',
        "ip_Address": this.store.get('userIP'),
        "device_Type": this.store.get('deviceInfo')?.deviceType,
      "device_Id": this.store.get('deviceInfo')?.deviceId,

      });
    }
      if (this.authToken) this.router.navigate(['/wishlist'])
      else this.router.navigate(['/login'])
    }
    else if(menu.name === 'navbar.Cart') {
      if(this.isGoogleAnalytics){
    this.$gaService.event(GaLocalActionEnum.CLICK_ON_CART_ICON, 'navigation', 'CART_ON_TOP_BANNER', 1, true , {
      "user_ID":this.userDetails?this.userDetails.mobileNumber:'Un_Authenticated',
      "ip_Address": this.store.get('userIP'),
      "device_Type": this.store.get('deviceInfo')?.deviceType,
      "device_Id": this.store.get('deviceInfo')?.deviceId,
      "session_ID": localStorage.getItem('sessionId')
    });
  }
     this.router.navigate(['/cart'])
    }
    else if(menu.name === 'navbar.Categories') {
      if(this.isGoogleAnalytics){
      this.$gaService.event(GaLocalActionEnum.CLICK_ON_CATEGORY, '', 'CATEGORY_PAGE', 1, true , {
        "user_ID":this.userDetails?this.userDetails.mobileNumber:'Un_Authenticated',
        "ip_Address": this.store.get('userIP'),
        "device_Type": this.store.get('deviceInfo')?.deviceType,
      "device_Id": this.store.get('deviceInfo')?.deviceId,
      });
    }
      this.router.navigate(['/categories-list'])
    }
    if(tempSelectedMenu?.name === this.selectedMenu?.name){
      this.commonService.isShowSearchBox.next(true);
    }else{
      this.commonService.isShowSearchBox.next(false);

    }
    this.selectedMenu = tempSelectedMenu;
  }

  renderMenuFilledIcon(menu: string) {
    const tempSelectedMenu : Menu | null  = this.floatingMenu.find((m: Menu) => m.name === menu) ?? null
    if(tempSelectedMenu) {
      if(tempSelectedMenu.name === 'navbar.Profile') {
        return this.router.url.includes('login') || this.router.url.includes('account');
      } else if(tempSelectedMenu.name === 'navbar.home') {
        return this.router.url === '/';
      }
      else if(tempSelectedMenu.name === 'navbar.Wishlist') {
        return this.router.url.includes('/wishlist');
      }
      else if(tempSelectedMenu.name === 'navbar.Cart') {
        return this.router.url === '/cart';
      }
      else if(tempSelectedMenu.name === 'navbar.Categories') {
        return this.router.url === '/categories-list';
      }
    }
  }
}
