import {ProductRate} from "./review";

export interface Product {
  id: number;
  productId: number;
  categoryName: any;
  type: string;
  productName: string;
  url_key: string;
  priceValue: string;
  salePriceValue: any;
  formated_price: string;
  short_description: string;
  description: string;
  sku: string;
  imageUrl: string;
  price: number;
  productImage: string;
  specsProductId: number;
  sessionId: string;
  rating: number;
  rates_count: number;
  rates_number_count: RatesNumberCount;
  images: Array<any>;
  base_image: ProductBaseImage;
  variants: Array<any>;
  in_stock: boolean;
  reviews: Array<ProductReviews>;
  is_saved: boolean;
  created_at: string;
  cartId: string;
  updated_at: string;
  categories: Array<any>;
  status: string;
  featured: number;
  new: number;
  active: number;
  width: string;
  height: string;
  weight: string;
  quantity: number;
  stockPerSKU:number;
  shopId: number;
  priceId: number;
  commission: string;
  isLiked: any;
  shopName: any;
  channelId:any;
  isHot:any;
  isNew:any;
  isBest:any;
  proSchedulingId:any
  review?: ProductRate;
  soldOut?: boolean;
  count: number | undefined;
  rate: number | undefined;
  currencyCode: string;
  categoryPath: string;
  categoryIds: string;
  thumbnailImageUrl?: any;
  name: string;
  masterImageUrl: string;
  specProductId: any;
  thumbnailImages: any;
  salePercent: number;
  productVariances : Array<any>
  badges : Array<any>
  itemPerCustomer?: number;
  stockStatusId: number;
  stockStatus: string;
}

export interface RatesNumberCount {
  has_five: number;
  has_four: number;
  has_three: number;
  has_two: number;
  has_one: number;
}

export interface ProductImage {
  id: number;
  path: string;
  url: string;
  small_image_url: string;
  medium_image_url: string;
  large_image_url: string;
  original_image_url: string;
}

export interface ProductBaseImage {
  small_image_url: string;
  medium_image_url: string;
  large_image_url: string;
  original_image_url: string;
}

export interface ProductReviews {
  name: string;
  title: string;
  comment: string;
  rating: string;
  created_at: string;
}

export interface AddProductToCart {
  ProductId: number;
  CartId: number;
  ProductName: string;
  ImageUrl: string;
  PriceId: number;
}

export enum FeatureType {
  HotDeals = 1,
  NewArrival = 2,
  BestSeller = 3,
  IsNew = 4,
  Featured = 5,
  Global=13
}

export enum FeaturedDataTypeEnum {
  Feature = 'feature',
  Category = 'category',
  Banner = 'banner',
  CategoryProduct = 'category-product',
}

export interface ProductBadge {
  name: string;
  translatedName: string;
  className: string;
  classNameMobile?:string;
}
