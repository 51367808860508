import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';

// Modules
import {ToastModule} from "primeng/toast";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DialogModule} from "primeng/dialog";
import {TranslateModule} from "@ngx-translate/core";
import {SidebarModule} from "primeng/sidebar";
import {DividerModule} from "primeng/divider";
import {RouterModule} from "@angular/router";
import {BadgeModule} from "primeng/badge";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {MatIconModule} from "@angular/material/icon";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DropdownModule} from "primeng/dropdown";
import {ButtonModule} from "primeng/button";
import {MessagesModule} from "primeng/messages";

// Components
import {HeaderComponent} from "../components/header/header.component";
import {FooterComponent} from "../components/footer/footer.component";
import {NavbarComponent} from "../components/navbar/navbar.component";
import {CategoryDropdownComponent} from "../components/category-dropdown/category-dropdown.component";
import {SideMenuComponent} from "../components/side-menu/side-menu.component";
import {SearchComponent} from "../components/search/search.component";
import {LoaderComponent} from "../components/loader/loader.component";
import {PagenotfoundComponent} from "../components/pagenotfound/pagenotfound.component";
import {
  TenantConfigurationComponent
} from "@pages/landing/components/tenant-configuration/tenant-configuration.component";
import {MainLandingComponent} from "@pages/landing/components/main-landing/main-landing.component";
import {ProductSliderComponent} from "../components/product-slider/product-slider.component";
import {ProductCardComponent} from "../components/product-card/product-card.component";

import {SectionComponent} from "../components/section/section.component";
import {CategoryCardComponent} from "../components/category-card/category-card.component";
import {TemplateOneComponent} from "@shared/components/landing-templates/template-one/template-one.component";
import {MtnMainSliderComponent} from "@shared/components/mtn-main-slider/mtn-main-slider.component";
import {
  PromotionBannerComponent
} from "@shared/components/landing-templates/promotion-banner/promotion-banner.component";
import {
  PromotionVerticalComponent
} from "@shared/components/landing-templates/promotion-vertical/promotion-vertical.component";
import {
  FeatureProductsComponent
} from "@shared/components/landing-templates/feature-products/feature-products.component";
import {CookieModalComponent} from "@shared/modals/cookie-modal/cookie-modal.component";
import {RatingModule} from "primeng/rating";
import {CarouselModule} from "primeng/carousel";
import {CarouselModule as OwlCarouselModule} from "ngx-owl-carousel-o";
import {SharedModule} from "@shared/modules/shared.module";
import {SuccessInfoModalComponent} from "@shared/modals/success-info-modal/success-info-modal.component";
import {NotifyModalComponent} from "@shared/modals/notify-modal/notify-modal.component";
import {CheckboxModule} from "primeng/checkbox";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input-gg";
import {FlashSaleModalComponent} from "@shared/modals/flash-sale-modal/flash-sale-modal.component";
import {MobileNavbarComponent} from "../components/mobile-navbar/mobile-navbar.component";
import { AgeConsentModalComponent } from '@shared/modals/age-consent-modal/age-consent-modal.component';
import { IneligablePurchaseModalComponent } from '@shared/modals/ineligable-purchase-modal/ineligable-purchase-modal.component';
import { BackButtonComponent } from '@shared/components/back-button/back-button.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { GAImpressionDirective } from '@core/directives/ga-impression.directive';

const APP_COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  NavbarComponent,
  CategoryDropdownComponent,
  SideMenuComponent,
  SearchComponent,
  LoaderComponent,
  PagenotfoundComponent,
  TenantConfigurationComponent,
  MainLandingComponent,
  ProductSliderComponent,
  ProductCardComponent,
  SectionComponent,
  CategoryCardComponent,
  TemplateOneComponent,
  PromotionBannerComponent,
  PromotionVerticalComponent,
  FeatureProductsComponent,
  MtnMainSliderComponent,
  CookieModalComponent,
  SuccessInfoModalComponent,
  NotifyModalComponent,
  FlashSaleModalComponent,
  MobileNavbarComponent,
  AgeConsentModalComponent,
  IneligablePurchaseModalComponent,
  // BackButtonComponent,
  ConfirmationDialogComponent
];

const APP_MODULES = [
  ToastModule,
  DialogModule,
  SidebarModule,
  DividerModule,
  BadgeModule,
  OverlayPanelModule,
  MatIconModule,
  ProgressSpinnerModule,
  DropdownModule,
  ButtonModule,
  MessagesModule,
  CarouselModule,
  OwlCarouselModule,
  NgOptimizedImage,
  CheckboxModule,
  NgxIntlTelInputModule,
  CommonModule,
  TranslateModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  RatingModule,
  GAImpressionDirective
];

@NgModule({
  declarations: [
    APP_COMPONENTS
  ],
    imports: [
        APP_MODULES
    ],
  exports: [
    APP_COMPONENTS,
    ToastModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    ProgressSpinnerModule,
    MessagesModule,
  ],
  providers: []
})
export class InitialModule { }
