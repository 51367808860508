import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {environment} from '@environments/environment';
import {StoreService, ProductService, PermissionService} from "@core/services";
import {GaLocalActionEnum} from "@core/enums/ga-local-action-enum";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import { debounceTime, distinctUntilChanged, fromEvent, map, switchMap } from 'rxjs';

@Component({
  selector: 'app-mtn-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {
  search: any = '';
  displayResult: boolean = false;
  searchResult: Array<any> = [];
  productId: number = 0;
  scConfig: boolean = false;
  isMobileTemplate:boolean=false;
  isGoogleAnalytics:boolean=false;
  @Output() onResult: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('inputField') inputField:ElementRef;
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);

    if (!clickedInside) {
      this.showResultPopUp(false);
    }
  }
  constructor(
    private store: StoreService, private productService: ProductService,
    private messageService: MessageService, private cd: ChangeDetectorRef,private elementRef: ElementRef,
    private permissionService: PermissionService,
    private translate: TranslateService, private router: Router ,
    private $gaService: GoogleAnalyticsService,
    ) {
    this.isMobileTemplate = this.permissionService.hasPermission('Mobile-Layout');
    this.isGoogleAnalytics = this.permissionService.hasPermission('Google Analytics');

    router.events
      .subscribe((event: any) => {
        if (event.navigationTrigger === 'popstate' || (event.navigationTrigger === 'imperative' && !event.url?.includes('search'))) {
          this.search = null;
        }
      });
    if (environment.isStoreCloud) {
      this.scConfig = true;
    } else {
      this.scConfig = false;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    /**/
  }


  ngAfterViewInit(): void {
    this.keyupSearch()
    this.store.subscription('search')
      .subscribe(
        (res: string) => {
          this.search = res;
        }
      );
  }

  searchProduct(event: any, searchKay?: any): void {
    if (event?.target?.value) {
      this.closeDropDown();
      this.router.navigate(
        ['/search'],
        {queryParams: {q: event?.target?.value}}
      );
    }

  }

  showResultPopUp(show: boolean): void {
    this.displayResult = show;
  }

  NavigateToProduct(productId: any) {

    this.router.navigate(['product/'+ productId+'/'+('1')],{
      queryParams: {
        tenantId: localStorage.getItem("tenantId"),
        lang:localStorage.getItem("lang")
      },
      queryParamsHandling: 'merge',
    })
    this.showResultPopUp(false);

  }

  closeDropDown() {
    this.showResultPopUp(false);

  }

  searchChange(event: any) {
    const regex = /^[^a-zA-Z0-9]+/;


    this.search = event.replace(regex, '');
    this.cd.detectChanges();
    if (this.search === '') {
      this.search = null
    }


  }
  // TODO: will be removed after testing the new solution

  // keyUpSearch(event:any){

  //   if (event.target.value && event.code != 'Enter') {

  //     this.productService.FilterWithSearchProductName(event.target.value).subscribe({
  //       next: (res: any) => {
  //         if(this.isGoogleAnalytics){
  //           this.$gaService.event(GaLocalActionEnum.CLICK_ON_SEARCH_BAR, '', 'SEARCH', 1, true, {
  //           searchKeywords: event?.target?.value
  //         });
  //       }
  //         this.searchResult = res.data.products.records;
  //         this.showResultPopUp(true);
  //       },
  //       error: (err: any) => {
  //         this.messageService.add({ severity: 'error', summary: this.translate.instant('ErrorMessages.fetchError'), detail: err.message });
  //       },
  //       complete: () => { }
  //     });
  //   }
  // }

  keyupSearch(){
    // if(event.target.value && event.target.value?.length > 2 && event.code != 'Enter'){
      fromEvent(this.inputField?.nativeElement, 'input').pipe(
        debounceTime(500), // Wait for 500ms pause in events
        map((event:any) => event), // Get the input value
        distinctUntilChanged(), // Only emit if the value has changed
        switchMap(event => {
            if (event.target.value && event.target.value?.length > 2 && event.code != 'Enter') {
              const query =event.target.value
              if(this.isGoogleAnalytics){
                this.$gaService.event(GaLocalActionEnum.CLICK_ON_SEARCH_BAR, 'search', 'SEARCH', 1, true, {
                "search_term": query
              });
            }
                return this.productService.FilterWithSearchProductName(query); // Call the API
            } else {
                return Promise.resolve([]); // Return an empty array for no query
            }
        })
    ).subscribe({
      next: (res: any) => {
     
        this.searchResult = res.data.products.records;
        this.showResultPopUp(true);
      },
      error: (err: any) => {
        this.messageService.add({ severity: 'error', summary: this.translate.instant('ErrorMessages.fetchError'), detail: err.message });
      },
      complete: () => { }
    });
    }
  
  // }
}
