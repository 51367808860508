export enum GaLocalActionEnum {
  CLICK_ON_CHANGE_COUNTRY = 'click_on_change_country',
  CLICK_ON_BURGER_MENU = 'click_on_burger_menu',
  CLICK_ON_ALL_MERCHANTS_LINK = 'click_on_all_merchants_link',
  CLICK_ON_CATEGORY = 'click_on_category',
  CLICK_ON_QUICK_LINKS = 'click_on_quick_links',
  CLICK_ON_SELL_ON_MARKETPLACE = 'click_on_sell_on_marketplace',
  CLICK_ON_TERMS_AND_CONDITIONS = 'click_on_terms_and_conditions',
  CLICK_ON_PRIVACY_POLICY = 'click_on_privacy_policy',
  CLICK_ON_SELL_ON_MARKETPLACE_FOOTER = 'click_on_sell_on_marketplace_footer',
  CLICK_ON_WISHLIST_FOOTER = 'click_on_wishlist_footer',
  CLICK_ON_WISHLIST_HEADER = 'click_on_wishlist_header',
  CLICK_ON_SEARCH_BAR = 'click_on_search_bar',
  CLICK_ON_CONTINUE_FOR_SIGN_IN = 'click_on_continue_for_sign_in',
  CLICK_ON_FORGOT_PASSWORD = 'click_on_forgot_password',
  CLICK_ON_OTP_VERIFY = 'click_on_otp_verify',
  CLICK_ON_BANNERS = 'click_on_banners',
  CLICK_ON_ACCOUNT_DETAILS = 'click_on_account_details',
  CLICK_ON_ADD_NEW_NUMBER_UNDER_DETAILS = 'click_on_add_new_number_under_details',
  CLICK_ON_ENTER_PASSWORD = 'click_on_enter_password',
  CLICK_ON_EDIT_ADDRESS = 'click_on_edit_address',
  CLICK_ON_DELETE_ADDRESS = 'click_on_delete_address',
  CLICK_ON_MORE_SELLER = 'click_on_more_seller',
  CLICK_ON_BUY_NOW = 'click_on_buy_now',
  CLICK_ON_CART_ICON = 'click_on_cart_icon',
  CLICK_ON_DELETE_CART_PAGE = 'click_on_delete_cart_page',
  CLICK_ON_CHANGE_QUANTITY = 'click_on_change_quantity',
  CLICK_ON_CONTINUE_SHOPPING = 'click_on_continue_shopping',
  CLICK_ON_PROCEED_TO_CHECKOUT = 'click_on_proceed_to_checkout',
  CLICK_ON_CHANGE_DELIVERY_OPTION = 'click_on_change_delivery_option',
  click_on_change_address = 'click_on_change_address',
  CLICK_ON_CHANGE_PHONE = 'click_on_change_phone',
  CLICK_ON_PROCEED_TO_PAYMENT = 'click_on_proceed_to_payment',
  CLICK_ON_PAY_WITH_MOMO_WALLET = 'click_on_pay_with_momo_wallet',
  CLICK_ON_PAY_WITH_CARD = 'click_on_pay_with_card',
  CLICK_ON_ADDRESS = 'click_on_address',
  CLICK_ON_ADD_ADDRESS = 'click_on_add_address',
  CLICK_ON_MY_ADDRESSES = 'click_on_my_addresses',
  CLICK_ON_MORE_FROM_THIS_SELLER = 'click_on_more_from_this_seller',
  CLICK_ON_SIGN_IN_UP= 'click_on_sign_in_up',
  CLICK_ON_SIGN_IN= 'click_on_sign_in',
  CLICK_ON_SIGN_UP= 'click_on_sign_up',
  CLICK_ON_COMPLETE_REGISTERATION= 'click_on_complete_registeration',
  CLICK_ON_ADD_TO_CART= 'click_on_add_to_cart',
  CLICK_ON_MOVE_TO_WISHLIST= 'click_on_move_to_wishlist',
  CLICK_ON_DELETE_CART= 'click_on_remove_from_cart',
  IMPRESSIONS = 'impressions',
}
