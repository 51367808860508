import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ActivatedRoute, Router} from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent  implements OnInit{
 successMsg:string = ''
  constructor(public dialogService: DialogService,
              public previousref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              private translate: TranslateService,
              private router: Router) {

  }
  ngOnInit(): void {
      this.successMsg = this.config?.data?.successMsg;

  }
  closeDialog(){
    this.previousref.close();
  }

}
