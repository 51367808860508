import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import UtilityFunctions from "@core/utilities/functions";
import {environment} from "@environments/environment";
import {isPlatformBrowser} from "@angular/common";
import {GaLocalActionEnum} from "@core/enums/ga-local-action-enum";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {PermissionService} from '@core/services'
@Component({
  selector: 'app-promotion-vertical',
  templateUrl: './promotion-vertical.component.html',
  styleUrls: ['./promotion-vertical.component.scss']
})
export class PromotionVerticalComponent {
  @Input() banners: any = [];
  isGoogleAnalytics: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any ,
              private $gaService: GoogleAnalyticsService,
              private permissionService:PermissionService
              ) {
                this.isGoogleAnalytics = this.permissionService.hasPermission('Google Analytics')
                
  }
  routeToCTA(banner:any,url: string) {
    if (isPlatformBrowser(this.platformId)) {
      if(this.isGoogleAnalytics){
      this.$gaService.event(GaLocalActionEnum.CLICK_ON_BANNERS, '', 'BANNERS_ON_HOMEPAGE', 1, true, {
        bannerId:  url.split('/').pop(),
        redirectPage: url
      });
    }
      if(banner.promotionId){
        if(banner.ctaLink) {
          const cta = banner.ctaLink.replace(/promotions\//g, "promotion/")
          if(!banner.ctaLink.includes('http://') && !banner.ctaLink.includes('https://')){
            window.location.href = 'https://'+cta;
          } else {
            window.location.href = cta;
          }
        } else {
          let tempurl=' https://'+environment.marketPlaceHostName+'/promotion/'+banner.promotionId;
          window.location.href = tempurl;
        }
      }
      else{
        window.location.href = url;
      }

    }
  }
  getBannerImages(url: string) {
    return UtilityFunctions.verifyImageURL(url, environment.apiEndPoint);
  }
}
