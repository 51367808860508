<p-dialog
  class="ineligable-purchase"
  [(visible)]="displayModal"
  [breakpoints]="{ '960px': '30vw', '768px': '30vw' }"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
>
  <ng-template pTemplate="content">
    <div class="content">
      <div class="content__icon">
        <img
        alt="Logo"
        height="52"
        ngSrc="assets/icons/quit-cancel.svg" width="52"/></div>
      <div class="content__text">
        Sorry, you're ineligible to purchase this product!
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="footer">
      <button
        pButton
        pRipple
        label="OK"
        class="cancel-btn"
        (click)="onCancel()"
      ></button>
    </div>
  </ng-template>
</p-dialog>
