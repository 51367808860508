import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

declare global {
  interface Window {
    MSStream: any;
  }
}
@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {
  deviceType: string = '';
  private deviceIdKey = 'device_id';

  constructor() { }

  isMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    return /android|iPhone|iPad|iPod/i.test(userAgent) && !window.MSStream;
  }

  isTablet(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    return /iPad|Android/i.test(userAgent) && !this.isMobile();
  }

  isDesktop(): boolean {
    return !this.isMobile() && !this.isTablet();
  }


  getDeviceType(): string {
    if (this.isMobile()) {
      this.deviceType = 'mobile';
    } else if (this.isTablet()) {
      this.deviceType = 'tablet';
    } else {
      this.deviceType = 'desktop';
    }
    return this.deviceType;
  }
  getDeviceId(): string {
    let deviceId = localStorage.getItem(this.deviceIdKey);
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem(this.deviceIdKey, deviceId);
    }
    return deviceId;
  }


}
