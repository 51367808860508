<div class="promotion-banner d-flex justify-content-center vertical-align-middle">
  <div class="banner-image banner-image-rounded" [ngStyle]="{'background-image': 'url('+ getBannerImages(banner.imageUrl) +')'}"
  (click)="routeToCTA(banner.ctaLink)"
  ></div>

  <!-- <img
    alt="No Image"
       ngSrc="{{getBannerImages(banner.imageUrl)}}"
       width="100" height="100" (click)="routeToCTA(banner.ctaLink)" class="cursor-pointer" *ngIf="banner.isActive"> -->
</div>
