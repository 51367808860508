import {Component, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import { Promotion } from "@core/interface";
import {AppDataService, PermissionService, ProductService, TenantService} from "@core/services";
import {isPlatformBrowser} from "@angular/common";
import {environment} from "@environments/environment";
import {Router} from "@angular/router";
import { GTMService } from '@core/services/gtm.service';

@Component({
  selector: 'app-landing-template-one',
  templateUrl: './template-one.component.html',
  styleUrls: ['./template-one.component.scss']
})
export class TemplateOneComponent {
  screenWidth: number;
  topNumber: number = 8;

  @HostListener('window:resize', ['$event'])

  // Main Banner
  mainBanner: any = [];

  // Featured Products
  firstFeaturedProducts: any = { id: 0, data: [], title: '',isActive:false, showLoader: true };
  secondFeaturedProducts: any = { id: 0, data: [], title: '',isActive:false, showLoader: true };
  thirdFeaturedProducts: any = { id: 0, data: [], title: '',isActive:false, showLoader: true };
  fourthFeaturedProducts: any = { id: 0, data: [], title: '',isActive:false, showLoader: true };

  // Promotions
  rowOnePromotions: Promotion[] = [];
  rowTwoPromotions: Promotion[] = [];
  rowThreePromotions: Promotion[] = [];
  rowFourPromotions: Promotion[] = [];
  rowFivePromotions: Promotion;
  bestSellerMobileBanner: Promotion;
  bestOfferMobileBanner: Promotion;

  rowOnePromotionResponsiveConfig: any[];
  rowThreePromotionResponsiveConfig: any[];
  displayFlashSaleModal:boolean=false;
  flashSaleData:any;
  flashSale:any;
  isMobileView = window.innerWidth <= 768;
  isMobileLayout: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
      this.isMobileView = this.screenWidth <= 768;
    }
  }

  constructor(private appDataService: AppDataService,
    private productService: ProductService, public tenantService: TenantService, private router: Router,
              private permissionService: PermissionService,
              private $gtmService:GTMService,
              @Inject(PLATFORM_ID) private platformId: any) {
    this.isMobileLayout = this.permissionService.hasPermission('Mobile-Layout')
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth
    }
    this.rowOnePromotionResponsiveConfig = [
      {
        breakpoint: '2400px',
        numVisible: 3,
        numScroll: 0
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1
      }
    ];
    this.rowThreePromotionResponsiveConfig = [
      {
        breakpoint: '1199px',
        numVisible: 3,
        numScroll: 0
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 1.5,
        numScroll: 1
      }
    ];
  }


  ngOnInit(): any {
    this.$gtmService.pushPageView('Home')
    this.fetchFeaturedProducts()
    this.fetchPromotions()
    this.fetchMainBanner()
    this.fetchFlashSale()

  }


  fetchFeaturedProducts(){
    const allFeatureProducts = this.appDataService.layoutTemplate.filter((section: any) => section.type === 'feature_product')

    if(allFeatureProducts.length) {
      allFeatureProducts.forEach((item: any) => {
        if(item.isActive){
          switch(item.data) {
            // Hot Deals Section
            case '1':
              if(this.tenantService.isAllowedCached.isHotDeals) {
                this.getFeatureProducts(parseInt(item.data), "first")
              } else {
                this.firstFeaturedProducts = localStorage.getItem('firstFeaturedProducts') ?
                JSON.parse(localStorage.getItem('firstFeaturedProducts') ?? '') : [];
              }
              break;
            // New Arrivals Section
            case '2':
              if(this.tenantService.isAllowedCached.isNewArrivals){
                this.getFeatureProducts(parseInt(item.data), "second")
        
              } else {
                this.secondFeaturedProducts = localStorage.getItem('secondFeaturedProducts') ?
                JSON.parse(localStorage.getItem('secondFeaturedProducts') ?? '') : [];
              }
              break;
            // Bestsellers Section
            case '3':
              if(this.tenantService.isAllowedCached.isBestSeller){
                this.getFeatureProducts(parseInt(item.data), "third")
              } else {
                this.thirdFeaturedProducts = localStorage.getItem('thirdFeaturedProducts') ?
                JSON.parse(localStorage.getItem('thirdFeaturedProducts') ?? '') : [];
              }
              break;
          }
        } else {
          this.firstFeaturedProducts.showLoader = false;
          this.secondFeaturedProducts.showLoader = false;
          this.thirdFeaturedProducts.showLoader = false;
        }
      });
    }
  }

  // fetchPromotions() {
  //   const allPromotions = this.appDataService.layoutTemplate.filter((section: any) => section.type === 'promotion')
  //   allPromotions.forEach((promotion: any) => {
  //     if (promotion.name === 'Promotion Card Left' || promotion.name === 'Promotion Card Middle' || promotion.name === 'Promotion Card Right') {
  //       const data: any = JSON.parse(promotion.data)
  //       data.ctaLink = data.CTALink
  //       data.isActive = promotion.isActive
  //       if (data.isActive) {
  //         this.rowOnePromotions.push(data)
  //       }
  //     }
  //     if (promotion.name === 'Discount Banner Left') {
  //       const data: any = JSON.parse(promotion.data)
  //       data.ctaLink = data.CTALink
  //       data.isActive = promotion.isActive
  //       if (data.isActive) {
  //         this.rowTwoPromotions.push(data)
  //       }
  //
  //     }
  //     if (promotion.name === 'Discount Banner Middle Left' || promotion.name === 'Discount Banner Middle Right') {
  //       const data: any = JSON.parse(promotion.data)
  //       data.ctaLink = data.CTALink
  //       data.isActive = promotion.isActive
  //       if (data.isActive) {
  //         this.rowThreePromotions.push(data)
  //       }
  //
  //     }
  //     if (promotion.name === 'Discount Banner Right Top' || promotion.name === 'Discount Banner Right Bottom') {
  //       const data: any = JSON.parse(promotion.data)
  //       data.ctaLink = data.CTALink
  //       data.isActive = promotion.isActive
  //       if (data.isActive) {
  //         this.rowFourPromotions.push(data)
  //       }
  //
  //     }
  //     if (promotion.name === 'Discount Banner Bottom') {
  //       const data: any = JSON.parse(promotion.data)
  //       data.ctaLink = data.CTALink
  //       data.isActive = promotion.isActive
  //       if (data.isActive) {
  //         this.rowFivePromotions = data;
  //       }
  //
  //     }
  //   })
  //
  // }


  fetchPromotions() {
    const allPromotions = this.appDataService.layoutTemplate.filter((section: any) => section.type === 'promotion');
    allPromotions.forEach((promotion: any) => {
      const data: any = JSON.parse(promotion.data);
      data.ctaLink = data.CTALink;
      data.isActive = promotion.isActive;

      if (data.isActive) {
        data['promotionId']=promotion.promotionId;
        switch (promotion.name) {
          case 'Promotion Card Left':
          case 'Promotion Card Middle':
          case 'Promotion Card Right':
            this.rowOnePromotions.push(data);
            break;

          case 'Discount Banner Left':
            this.rowTwoPromotions.push(data);
            break;

          case 'Discount Banner Middle Left':
          case 'Discount Banner Middle Right':
            this.rowThreePromotions.push(data);
            break;

          case 'Discount Banner Right Top':
          case 'Discount Banner Right Bottom':
            this.rowFourPromotions.push(data);
            break;

          case 'Discount Banner Bottom':
            this.rowFivePromotions = data;
            break;
        }
      }
    });

    const bestSellerMobileBanner = this.appDataService.layoutTemplate.find((section: any) => section.type === 'best_seller_banner');
    if(bestSellerMobileBanner) {
      const data: any = JSON.parse(bestSellerMobileBanner.data);
      data.ctaLink = data.CTALink;
      data.isActive = bestSellerMobileBanner.isActive;
      this.bestSellerMobileBanner = data;
    }

    const bestOfferMobileBanner = this.appDataService.layoutTemplate.find((section: any) => section.type === 'best_offers_banner');
    if(bestOfferMobileBanner) {
      const data: any = JSON.parse(bestOfferMobileBanner.data);
      data.ctaLink = data.CTALink;
      data.isActive = bestOfferMobileBanner.isActive;
      this.bestOfferMobileBanner = data;
    }

  }


  fetchMainBanner() {

    const allBanners = this.appDataService.layoutTemplate.find((section: any) => section.type === 'main_banner')
    if (allBanners) {
      if (allBanners?.isActive)
        this.mainBanner = JSON.parse(allBanners.data)
      if (this.mainBanner.length > 0) {
        this.mainBanner = this.mainBanner.filter((item: any) => !item.isMerchantbanner);
      }
      this.mainBanner=this.filterInactivebanners(this.mainBanner);
    }
  }
  filterInactivebanners(tempData:any){
    let currentDate:any=Date.now();
    let data=tempData.filter((banner:any)=> {

      let endDate=new Date(banner.endDate).getTime()
      let startDate=new Date(banner.startDate).getTime()
      if(!banner.promotionId){
        if(!endDate){
          return banner;
          }
          else{
          if(endDate && endDate>=currentDate && startDate && startDate<=currentDate){
          return banner;
          }
        }
      }
      if(banner.promotionId && endDate && endDate>=currentDate && startDate && startDate<=currentDate){
        return  banner;
      }
    })
    return data

  }
  getFeatureProducts(featureId: number, featureSequence: string) {

    let featureTopNumber = 8
    let featureShowOutOfStock = false;

    const showRoomRecords = this.appDataService.showRoomConfiguration.records
    if(showRoomRecords.length) {
      const featureRecord = showRoomRecords.find((res:any) => res.showRoomTypeId == 2 && res.featureProduct == featureId)
      if(featureRecord && featureRecord?.topNumber){
        featureTopNumber = featureRecord.topNumber
      }
      if(featureRecord && featureRecord?.showOutOfStock) {
        featureShowOutOfStock = featureRecord.showOutOfStock
      }
    }

    let pageSize=featureTopNumber;



    this.productService
      .GetAllProductsByFeature(featureId, featureTopNumber, false, 1, pageSize, false, featureShowOutOfStock,true)
      .subscribe({
        next: (res: any) => {
          if (res?.data?.records?.length) {
            const tempProducts: any = [];
            res.data?.records.forEach((record: any) => {
             this.addTempProduct(res,tempProducts,record,featureSequence,featureId, featureTopNumber);
            })
          } else {
            this.setFeatureProducts(featureSequence, featureId, [], res?.data?.featureName, featureTopNumber)
          }
        },
      });
  }

  fetchFlashSale(){
     this.flashSale = this.appDataService.layoutTemplate.find((section: any) => section.description === 'Flash Sale')
    const visited:any=localStorage.getItem('visited');
    if(this.flashSale?.isActive && this.flashSale?.promotionId && !visited && this.flashSale?.data){
        this.flashSaleData=JSON.parse(this.flashSale.data);
        this.displayFlashSaleModal=true;
    }

  }

  addTempProduct(res:any,tempProducts:any,record: any,featureSequence:any,featureId:any, featureTopNumber: number) {
    let selectedVariance;
    let defaultVariant = record?.productVariances?.find((variant: any) => variant.isDefault)
    if (defaultVariant) {
      selectedVariance = defaultVariant;
    } else {
      let approvedVariant = record?.productVariances?.find((variant: any) => !variant.soldOut);
      if (approvedVariant) {
        selectedVariance = approvedVariant;

      } else {
        selectedVariance = record?.productVariances[0];
      }

    }
    if (selectedVariance) {
      let features=selectedVariance?.productFeaturesList[0]?.featureList;
      let product = {
        productId: record?.id,
        productName: record?.name,
        isLiked: record?.isLiked,
        priceValue: selectedVariance?.price,
        priceId: selectedVariance?.priceId,
        salePriceValue: selectedVariance?.salePrice,
        currencyCode: record?.currencyCode,
        masterImageUrl: selectedVariance.masterImageUrl ?? (selectedVariance.images ? selectedVariance.images[0] : ''),
        thumbnailImages: selectedVariance?.thumbnailImages,
        soldOut: selectedVariance?.soldOut,
        rate: selectedVariance?.rate,
        count: selectedVariance?.count ?? 0,
        salePercent: selectedVariance?.salePrice ? 100 - (selectedVariance?.salePrice / selectedVariance?.price * 100) : 0,
        shopId: record.shopId,
        specProductId: selectedVariance.specProductId,
        channelId: record.channelId ?? '1',
        isHot:!!features?.includes(1),
        isNew:!!features?.includes(2),
        isBest:!!features?.includes(3),
        quantity:selectedVariance.quantity,
        proSchedulingId:selectedVariance.proSchedulingId,
        stockPerSKU:selectedVariance.stockPerSKU,
        stockStatus: selectedVariance.stockStatus,
        sku:selectedVariance?.sku,
        skuAutoGenerated : selectedVariance.skuAutoGenerated

      }
      if (product.salePriceValue) {
        product.salePercent = 100 - (product.salePriceValue / product.priceValue * 100);
      }

      tempProducts.push(product)
    }

    this.setFeatureProducts(featureSequence, featureId,tempProducts,res?.data?.featureName, featureTopNumber);
    }

  setFeatureProducts(featureSequence: any, featureId: any, tempProducts: any,featureName:any, topNumberLimit: number = 8) {
    // todo: this slicing needs to be discussed may be this one is may be handled from styling
    if (featureSequence === 'first') {

      let firstProductsCount=this.rowTwoPromotions.length>0 ? topNumberLimit : 10;
      this.firstFeaturedProducts = {
        id: featureId,
        data: tempProducts.slice(0, firstProductsCount),
        title: featureName,
        showLoader: false,
        isActive:true
      };
      localStorage.setItem('firstFeaturedProducts', JSON.stringify(this.firstFeaturedProducts));
    } else if (featureSequence === 'second') {
      let secondProductsCount=this.rowFourPromotions.length>0 ? topNumberLimit : 10;
      this.secondFeaturedProducts = {
        id: featureId,
        data: tempProducts.slice(0, secondProductsCount),
        title: featureName,
        showLoader: false,
        isActive:true
      };
      localStorage.setItem('secondFeaturedProducts', JSON.stringify(this.secondFeaturedProducts));
    } else if (featureSequence === 'third') {
      this.thirdFeaturedProducts = {
        id: featureId,
        data: tempProducts.slice(0, topNumberLimit),
        title: featureName,
        showLoader: false,
        isActive:true
      };
      localStorage.setItem('thirdFeaturedProducts', JSON.stringify(this.thirdFeaturedProducts));

    }else if (featureSequence === 'fourth') {
      this.fourthFeaturedProducts = {
        id: featureId,
        data: tempProducts.slice(0, topNumberLimit),
        title: featureName,
        showLoader: false,
        isActive:true
      };
      localStorage.setItem('fourthFeaturedProducts', JSON.stringify(this.fourthFeaturedProducts));

    }

    }
  onFlashCancel() {

    this.displayFlashSaleModal = false;
  }
  routeToCTA() {
    this.displayFlashSaleModal = false;
    if (isPlatformBrowser(this.platformId)) {
      if(this.flashSale.promotionId) {
        const data = JSON.parse(this.flashSale.data)
        let tempurl;
        // if(data.promotionName) {
        //   tempurl = ' https://' + environment.marketPlaceHostName + '/promotion/' + data.promotionName;
        // } else{
        //   tempurl = ' https://' + environment.marketPlaceHostName + '/promotion/' + this.flashSale.promotionId;
        // }
        // window.open(tempurl, '_blank');
        this.router.navigate([`/promotion/${data.promotionName}`])
      }
      else{
        window.open(this.flashSaleData.CTALink, '_blank');
      }
    }
  }


}
