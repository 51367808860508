import {Component, Input, OnInit} from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";

import {ProductRate, Currency} from '@core/interface';

import {ReviewsService, StoreService} from '@core/services';

@Component({
  selector: 'app-mtn-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent implements OnInit {
  options: OwlOptions = {} as OwlOptions;
  currency: Currency = {} as Currency;
  @Input() products: any=[];
  reviews: ProductRate[] | undefined;

  isDragging: boolean = false;
  newProduct: any = [];

  constructor(
    private store: StoreService,
    private reviewsService: ReviewsService
  ) {
  }

  ngOnInit(): void {
    this.initData();


    this.products.forEach((obj: any) => {
      let selectedVariance;
      let defaultVariant = obj?.productVariances.find((variant: any) => variant.isDefault)
      if (defaultVariant) {
        selectedVariance = defaultVariant;
      } else {
        let approvedVariant = obj?.productVariances.find((variant: any) => !variant.soldOut);
        if (approvedVariant) {
          selectedVariance = approvedVariant;

        } else {
          selectedVariance = obj?.productVariances[0];
        }
      }
      let features=[];
      if(selectedVariance?.productFeaturesList){
        features=selectedVariance?.productFeaturesList[0]?.featureList;
      }

      this.newProduct.push({
        productId: obj?.id,
        productName: obj?.name,
        priceValue: selectedVariance?.price,
        salePriceValue: selectedVariance?.salePrice,
        currencyCode: obj?.currencyCode,
        masterImageUrl: selectedVariance?.masterImageUrl,
        thumbnailImages: selectedVariance?.thumbnailImages,
        soldOut: selectedVariance?.soldOut,
        rate: selectedVariance?.rate,
        count: selectedVariance?.count ?? 0,
        specProductId: selectedVariance.specProductId,
        channelId: obj.channelId ?? 1,
        salePercent: selectedVariance?.salePrice?100 - (selectedVariance?.salePrice / selectedVariance?.price * 100):0,
        shopId: obj.shopId,
        isHot:features?.includes(1),
        isNew:features?.includes(2),
        isBest:features?.includes(3),
        quantity:selectedVariance.quantity,
        proSchedulingId:selectedVariance.proSchedulingId,
        stockPerSKU:selectedVariance.stockPerSKU,
        sku:selectedVariance?.sku,
        skuAutoGenerated : selectedVariance.skuAutoGenerated

      });


    })

  }


  ngAfterViewInit(): void {
    setTimeout(() => {


      this.store.subscription('currency')
        .subscribe({
          next: (res) => this.currency = res
        });
    }, 10);
  }

  initData(): void {
    this.options = {
      stagePadding: 0,
      loop: false,
      autoplay: true,
      dots: false,
      autoWidth: true,
      nav: true,
      lazyLoad: true,
      autoplayHoverPause: true,

      rewind: false,
      margin: 30,
      navText: [],
      responsive: {
        0: {
          items: 2,
          nav: false,
        },
        300: {
          items: 2,
          nav: false
        },
        400: {
          items: 3,
          nav: false
        },
        600: {
          items: 4
        },
        740: {
          items: 5
        },
        800: {
          items: 6
        },
        940: {
          items: 7
        },
        1280: {
          items: 8
        },
        1300: {
          items: 9
        },
        1400: {
          items: 6
        },
        1600: {
          items: 6
        },
      }
    };
  }

  carouselDrag(event: boolean) {
    this.isDragging = event;
  }
}
