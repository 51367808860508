<div class="promotion-vertical d-flex flex-column justify-content-space-between">
  <div class="promotion-vertical-section d-inline-flex" *ngFor="let banner of banners" [ngClass]="{'promotion-vertical-section-small':banners.length>1}">
    <div (click)="routeToCTA(banner,banner.ctaLink)" class="banner-image banner-image-rounded" [ngStyle]="{'background-image': 'url('+ getBannerImages(banner.imageUrl) +')'}"></div>

    <!-- <img
      alt="No Image"
      ngSrc="{{getBannerImages(banner.imageUrl)}}"
      width="100" height="100" (click)="routeToCTA(banner,banner.ctaLink)" class="cursor-pointer" *ngIf="banner.isActive"> -->
  </div>
</div>
