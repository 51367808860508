<p-dialog
  class="age-consent"
  [(visible)]="displayModal"
  [breakpoints]="{ '960px': '75vw', '768px': '75vw' }"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
>
  <ng-template pTemplate="content">
    <div class="content">
      <div class="content__age">
        <span>{{ age }}</span
        >+
      </div>
      <div class="content__text">
        <div class="pb-3">Are you sure you're {{ age }} years or older?</div>
        <div>Please Verify your age to buy this product.</div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="footer">
      <button
        pButton
        pRipple
        label="Yes I am {{ age }} years or older"
        class="submit-btn"
        (click)="onSumbit()"
      ></button>

      <button
        pButton
        pRipple
        label="No, I am under {{ age }} years"
        class="cancel-btn"
        (click)="onCancel()"
      ></button>
    </div>
  </ng-template>
</p-dialog>
