import {Language} from "./language";
import {UserConsentType} from "@core/enums/user";

export class ApiResponse {
  data: any;
  success: boolean;
  message?: string;
}

export class InitialData {
  isShop: boolean;
  languages: Language[];
  result: InitialDataResult;
  shopProductSetting: any;
  featureByTenantRes :[];
}

export class InitialDataResult {
  hasNext: boolean;
  records: InitialDataResultRecords[];
  total: number;
}

export class InitialDataResultRecords {
  displayName: string;
  id: number;
  isDelete: boolean;
  key: string;
  keyTypeId: number;
  value: any;
}

export class ConfigurationResponse {
  hasNext: false;
  records: ConfigurationRecords[];
  total: number
}

export class ConfigurationRecords {
  id: number;
  key: string;
  value: string;
}

export class Tenant {
  hasNext: boolean;
  records: TenantRecords[];
  total: number;
}

export class TenantRecords {
  id: number;
  code: string;
  countryId: string;
  isoCode: string;
  name: string;
  nameAr: string;
  tenantId: number;
  selected?: boolean;
}

export class Categories {
  hasNext: boolean;
  records: CategoryRecords[];
  total: number;
}

export class CategoryRecords {
  id: number;
  categories: CategoryRecords[];
  categoryIds: number[];
  categoryName: string;
  categoryNameAr: string;
  categoryPath: string;
  categoryStatus: boolean;
  image: string;
  isRefundable: boolean;
  isVirtual: boolean;
  order: number;
  parentId: number;
  shopId: number;
  templateId: number;
  totalProductCount: number;
}


export interface ShowroomConf {
  bannerId: number;
  bannerSize: any;
  categoryId: any;
  color: any;
  featureProduct: any;
  id: number;
  image: string;
  includeSideBanner: boolean;
  order: number;
  searchData: any;
  shopId: any;
  showRoomTypeId: number;
  topNumber: number;
}

export interface AllShowroomConf {
  data: {
    showRoomConfigurationRes: {
      records: ShowroomConf[];
      total: number;
      hasNext: boolean;
    };
  };
  success: true;
}


export interface Offers {
  creationDate: string;
  id: number;
  imageUrl: string;
  isDelete: boolean;
  offerPercent: number;
  shopId: any;
  tenantId: number;
  updateOn: any;
}

export interface AllOffers {
  data: { records: Offers[]; total: number; hasNext: boolean };
  success: true;
}

export interface BannerResponse {
  data: Banner;
  success: true;
}
export interface Banner {
  categoryId: number;
  creationDate: string;
  description: string;
  id: number;
  imageUrl: string;
  isDelete: boolean;
  redirectTypeId: number;
  redirectValue: number;
  shopId: number;
  tenantId: number;
  title: string;
  updateOn: string;
}


export interface UserConsent {
  sessionId: string;
  userId?: string;
  consentType: UserConsentType,
  consent: boolean,
  recordId?: number
}

export interface AgeConsent {
  sessionId: string;
  userId?: string;
  MinimumAgeForProductEligibility: number;
  recordId?: number;
}
