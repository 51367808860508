<div class="d-flex mobile-navbar" *ngIf="isMobileTemplate">

  <div *ngFor="let menu of floatingMenu" class="mobile-navbar__navs" (click)="navigateFloating(menu)">
    <img alt="No Image" [src]="renderMenuFilledIcon(menu.name) ? menu.filledIcon : menu.icon">
    <p-badge *ngIf="menu.name === 'navbar.Cart'" class="mobile-navbar__navs__badge" value="{{ cartListCount }}">

    </p-badge>

    <div class="text-capitalize"
    [ngClass]="renderMenuFilledIcon(menu.name) ? 'mobile-navbar__navs__name-filled' : 'mobile-navbar__navs__name'">{{menu.name | translate}}</div>
  </div>
</div>
