<ng-container *ngIf="desktopView || !isMobileTemplate">
  <div class="v3-header" >
    <header class="d-flex flex-wrap justify-content-space-between" [ngClass]="{'mobile-header-template': isMobileTemplate}">
      <div id="header-zoom-container" class="main-header">

        <div class="main-header__section d-flex">
          <div class="w-50 d-inline-flex" >
            <img *ngIf="!scConf" alt="Logo" class="d-flex header-logo align-items-center justify-content-center"
                 routerLink="/" src="assets/icons/logo-marketplace.png"/>
            <img *ngIf="scConf" alt="Logo" class="d-flex header-logo align-items-center justify-content-center"
                 routerLink="/"
                 src="assets/icons/white-logo.svg"/>
          </div>

          <div class="w-50 select-location">
            <svg height="16" id="a559119926c5ebd4e4ff6e365c5da3c1" viewBox="0 0 15.999 16" width="15.999"
                 xmlns="http://www.w3.org/2000/svg">
              <g data-name="Group 1082" id="Group_1082">
                <path d="M0,0H16V16H0Z" data-name="Path 9826" fill="none" id="Path_9826"/>
                <path d="M0,0H16V16H0Z" data-name="Path 9827" fill="none" id="Path_9827"/>
              </g>
              <g data-name="Group 1083" id="Group_1083" transform="translate(1.678 0.668)">
                <path
                  d="M10.334,12.335a.667.667,0,0,1-.607-.391C9.272,10.944,7,5.875,7,4.335a3.333,3.333,0,1,1,6.666,0c0,1.54-2.271,6.609-2.727,7.609a.663.663,0,0,1-.607.391Zm0-10a2,2,0,0,0-2,2,27.475,27.475,0,0,0,2,5.684,27.461,27.461,0,0,0,2-5.684A2,2,0,0,0,10.334,2.335Z"
                  data-name="Path 9828" fill="#fff" id="Path_9828" transform="translate(-4.012 -1.002)"/>
                <circle cx="1.153" cy="1.153" data-name="Ellipse 15" fill="#fff" id="Ellipse_15" r="1.153"
                        transform="translate(5.096 2.407)"/>
                <path
                  d="M13.829,18H3.851a1.333,1.333,0,0,1-1.225-1.859l1.428-3.332A1.33,1.33,0,0,1,5.28,12h.549v1.333H5.28L3.851,16.669h9.978L12.4,13.336h-.571V12H12.4a1.33,1.33,0,0,1,1.225.809l1.428,3.333A1.333,1.333,0,0,1,13.829,18Z"
                  data-name="Path 9829" fill="#fff" id="Path_9829" transform="translate(-2.517 -4.669)"/>
              </g>
            </svg>
            <div (click)="!isShowYello && showDialog = true"
                 [ngClass]="{ 'pointer-events-none': selectedTenant?.tenantId === '3' }" class="delivery cursor-pointer">
              <span>{{ "header.deliverTo" | translate }} </span>
              <div class="deliver-to" *ngIf="!isShowYello && selectedTenant">
              {{ selectedTenant?.name }} <em class="pi pi-angle-down text-white ml-3"></em></div>
              <select *ngIf="isShowYello" #langSelect (change)="changeCountry(langSelect.value)"   class="shadow-none border-light selector"  id="language-dropdown" name="language">
                <option *ngFor="let country of countries" [selected]="country.isoCode === selectedCountry"
                        [value]="country.tenantId">
                  {{ country.name  }}
                </option>
              </select>
            </div>
          </div>
        </div>


        <div class="main-header__section d-inline-flex w-100 search-bar">
          <app-mtn-search (onResult)="isActiveSearch = $event"
                          class="d-flex w-100 align-items-center justify-content-center"></app-mtn-search>
        </div>

        <div class="main-header__section d-flex header-left-section">

          <!-- To Change The Language -->
          <!-- <div class="d-inline-flex language-dropdown">
            <select #langSelect (change)="changeLang(langSelect.value)" [disabled]="true" id="language-dropdown"
                    name="language">
              <option *ngFor="let language of languages" [selected]="language.code === selectedLanguage"
                      [value]="language.code">
                {{ language.name === "French" ? "Français" : language.name }}
              </option>
            </select>
          </div> -->

          <div class="d-inline-flex wishlist-icon">
            <a (click)="openWishlist()" class="d-flex mx-3 cursor-pointer align-items-center justify-content-center">
              <img alt="No Image" src="assets/icons/heart-empty.svg"/>
              <span class="ml-1">
            {{ "header.wishList" | translate }}
          </span>
            </a>
          </div>

          <div class="d-inline-flex cart-icon" (click)="openCart()">
            <a class="d-flex mx-3 cursor-pointer align-items-center justify-content-center" >
          <span class="mx-3 cursor-pointer" style="position: relative">
            <img alt="No Image" class="mr-1" src="assets/images/header/icon1.svg"/>
            <p-badge class="badge-mobile" value="{{ cartListCount }}"></p-badge>
            {{ "header.cart" | translate }}
          </span>
            </a>
          </div>

          <div *ngIf="!authToken" class="d-inline-flex unauth-signin">
            <a class="d-flex flex-row align-items-center justify-content-center" (click)="signIn()">
              <img alt="No Image" class="login-icon" src="assets/icons/user-account.svg" style="margin-right: 10px">

              <div>
                <div>
                  {{ "header.signIn" | translate }}
                </div>
              </div>
            </a>
          </div>

          <ng-container *ngIf="authToken">
            <div class="actions d-inline-flex">
              <p-overlayPanel #op [appendTo]="'body'">
                <ng-template pTemplate>
                  <div class="profile-menu">
                    <div (click)="op.hide()" class="my-2 profile-menu-item">
                      <a class="cursor-pointer no-underline main-color font-size-15px regular-font"
                         routerLink="/orders">{{
                        "header.yourOrders" | translate }}</a>
                    </div>
                    <div (click)="op.hide()" class="my-2 profile-menu-item">
                      <a (click)="openAccountAddress()" class="cursor-pointer no-underline main-color font-size-15px regular-font"
                        >{{ "header.yourAddresses" | translate }}</a>
                    </div>
                    <div (click)="op.hide()" class="my-2 profile-menu-item">
                      <a (click)="showAccountDetails()" class="cursor-pointer no-underline main-color font-size-15px regular-font"
                         >{{
                        "header.yourDetails" | translate }}</a>
                    </div>
                    <div (click)="op.hide()" class="my-2 profile-menu-item">
                      <a (click)="logOut()"
                         class="cursor-pointer no-underline main-color font-size-15px regular-font logout-user">{{
                        "header.logout" | translate }}</a>
                    </div>
                  </div>
                </ng-template>
              </p-overlayPanel>
              <span (mouseenter)="op.toggle($event)"
                    class="cursor-pointer d-flex mx-3 cursor-pointer align-items-center justify-content-center user-name"
                    id="profile">
            <em class="pi pi-user pi-white"></em>
            <span class="ml-2 d-inline-flex flex-nowrap ml-2 text-overflow-ellipsis w-6rem" style="white-space: nowrap">
              {{ "header.hi" | translate }}
              {{ Name | slice : 0 : 6 }}
              {{ Name.length > 6 ? "..." : "" }}
            </span>
          </span>
            </div>
          </ng-container>
        </div>

      </div>

    </header>
    <ng-container *ngIf="desktopView; else showNavBar">
      <div  *ngIf="navbarData?.isActive" class="v3-header-cateogries">
        <div class="header-category">
          <div id="header-zoom-container">
            <div class="col-12 px-0 pb-0 header-mtn">
              <div class="flex flex-row">
                <app-mtn-navbar class="category-navbar w-100"></app-mtn-navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #showNavBar>
      <div class="v3-header-cateogries">
        <div class="header-category" style="top: 160px !important;">
          <div id="header-zoom-container">
            <div class="col-12 px-0 pb-0 header-mtn">
              <div class="flex flex-row">
                <app-mtn-navbar class="category-navbar w-100"></app-mtn-navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>


  </div>
</ng-container>


<ng-container  *ngIf="!desktopView && isMobileTemplate">
 <div class="header-mobile-new">
   <div class="v3-header" style="height: 75px !important;">
     <header [ngClass]="{'mobile-header-template': isMobileTemplate}">
       <div id="header-zoom-container" class="main-header">
         <div class="main-header__section d-flex align-items-center">
           <div class="main-header__section__logo" *ngIf="!isExpanded">
             <img *ngIf="!scConf" alt="Logo" class="d-flex header-logo" routerLink="/" src="assets/icons/new-logo-marketplace-mobile.svg"/>
             <img *ngIf="scConf" alt="Logo" class="d-flex header-logo" routerLink="/" src="assets/icons/white-logo.svg"/>
           </div>
           <div class="main-header__section__search" *ngIf="!isExpanded">
             <div class="d-flex justify-content-end">
               <div class="main-header__section__search__flag"  *ngIf="!isExpanded" (click)="!isShowYello && showDialog = true">
                <span *ngIf="!isShowYello && selectedTenant">
                  <img class="flar-header-img" [src]="getImage(selectedTenant?.flag)">
                </span>
               </div>
               <div class="search-container">
                 <div class="search-icon" (click)="toggleSearchBar()">
                   <em class="pi pi-search"></em>
                 </div>
               </div>
             </div>
           </div>

           <div class="search-bar" *ngIf="isExpanded && isShowSearch">
             <app-mtn-search
               (onResult)="isActiveSearch = $event"
               [class.width-animation]="isExpanded"
               [ngClass]="{ 'overflow-hidden': !isExpanded }"
               class="search-bar-new"></app-mtn-search>
             <div class="search-icon search-icon-abs" (click)="toggleSearchBar()">
               <em class="pi pi-search"></em>
             </div>
           </div>

           <!--          <div class="flag-top"-->
           <!--               [ngClass]="{ 'pointer-events-none': selectedTenant?.tenantId === '3' }">-->

           <!--            -->
           <!--          </div>-->
           <!--          <div class="search-container " [ngClass]="{ 'expanded': isExpanded }">-->

           <!--            <div class="search-icon" (click)="toggleSearchBar()">-->
           <!--              <em class="pi pi-search"></em>-->
           <!--            </div>-->

           <!--            <app-mtn-search (onResult)="isActiveSearch = $event" [class.width-animation]="isExpanded" [ngClass]="{ 'overflow-hidden': !isExpanded }" class="search-bar-new"></app-mtn-search>-->
           <!--          </div>-->
         </div>

       </div>
     </header>
     <div *ngIf="navbarData?.isActive" class="v3-header-cateogries d-none">
       <div class="header-category">
         <div id="header-zoom-container">
           <div class="col-12 px-0 pb-0 header-mtn">
             <div class="flex flex-row">
               <app-mtn-navbar class="category-navbar w-100"></app-mtn-navbar>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
</ng-container>
<p-dialog [(visible)]="showDialog" (onHide)="hideDialog()" [breakpoints]="{ '960px': '75vw', '640px': '90vw' }" [dismissableMask]="true"
          [draggable]="false" [modal]="true" [resizable]="false" [showHeader]="false" class="confirmation-modal">
  <ng-template pTemplate="content">
    <div class="content">
      <img alt="No Image" alt="Logo" class="mb-3" src="assets/images/logo-marketplace1.svg"/>
      <h2 class="mb-1 select-country">{{ "landingPage.selectCountry" | translate }}</h2>
      <p class="country-desp mb-3">{{ "landingPage.clickOnCountry" | translate }}</p>

      <div class="product-search p-inputgroup mb-3">
        <span class="p-inputgroup-addon color"><em class="pi pi-search"></em></span>
        <form autocomplete="off" class="ng-untouched ng-pristine ng-valid" novalidate="" style="display: contents">
          <input (input)="searchCountries($event)" [(ngModel)]="country" [ngModelOptions]="{ standalone: true }"
                 autocapitalize="off" autocomplete="new-password" autocorrect="off"
                 class="p-inputtext p-component p-element ng-untouched ng-pristine ng-valid" pinputtext=""
                 placeholder="{{ 'landingPage.searchCountry' | translate }}" spellcheck="false" type="text"/>
        </form>
      </div>
      <div *ngIf="countriesList?.length === 0" class="col-12 text-center">
        <p class="no-found">{{ "landingPage.noCountryFound" | translate }}</p>
      </div>
      <span class="line-border"></span>
      <div class="row countries-scoll">
        <div (click)="setTenant(item?.tenantId,item)" *ngFor="let item of countriesList"
             class="col-4 text-center mobile-show-countries">
          <img [src]="countryFlagImages(item?.flag)" alt="No Image" class="country-flag"/>
          <p class="country-name">{{ item?.name }}</p>
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>
