import { environment } from "@environments/environment"

export class EndPointsConfig {
    public static get ApiUrl() {
        return {
            Url: `${environment.apiEndPoint}`
        }
    }

    public static get Tenants() {
        return {
            Controller: "Tenant/Tenants",
            EndPoints: {
                GetAllTenant: "GetAllTenant"
            }
        }
    }

    public static get Shop() {
        return {
            Controller: "Tenant/Shop",
            EndPoints: {
                CreateShop: "CreateShop",
                CreateShopAdmin: "CreateShopAdmin",
                GetMarketplaceShop: "GetMarketplaceShop",
                GetAllShops: "GetAllShops"
            }
        }
    }

    public static get Category() {
        return {
            Controller: "Product/Category",
            EndPoints: {
                GetAllCategory: "GetAllCategory",
                GetCategoryById: "GetCategoryById",
                CreateCategory: "CreateCategory",
                UpdateCategory: "UpdateCategory",
                DeleteCategory: "DeleteCategory",
                DisableCategory: "DisableCategory"
            }
        }
    }

    public static get Order() {
        return {
            Controller: "Order/Order",
            EndPoints: {
                GetAllOrder: "GetAllOrder",
                CreateOrder: "CreateOrder",
                GetOrderById: "GetOrderById",
                RefundOrder: "RefundOrder",
                GetCustomerOrder: "GetCustomerOrder",
                RequestOrderRefund: "RequestOrderRefund",
                UpdateOrderStatus: "UpdateOrderStatus"
            }
        }
    }

    public static get OrderDetails() {
        return {
            Controller: "Order/OrderDetail",
            EndPoints: {
                GetOrderDetailsByOrderId: "GetOrderDetailsByOrderId",
                GetAllOrderDetail: "GetAllOrderDetail",
                GetAllSubOrdersWithPayments: "GetAllSubOrdersWithPayments"
            }
        }
    }

    public static get User() {
        return {
            Controller: "Auth/User",
            EndPoints: {
                GetUserDetails: "GetUserDetails",
                MarketPlaceAdminById: "MarketPlaceAdminById",
                MarketPlaceAdmin: "MarketPlaceAdmin"
            }
        }
    }

    public static get Product() {
        return {
            Controller: "Product/Product",
            EndPoints: {
                GetAllProduct: "GetAllProduct",
                ApproveProduct: "ApproveProduct"
            }
        }
    }

    public static get SpecsProduct() {
        return {
            Controller: "Product/SpecsProduct",
            EndPoints: {
                GetSpecsDetailProductById: "GetSpecsDetailProductById"
            }
        }
    }


    public static get RefundReason() {
        return {
            Controller: "Order/RefundReason",
            EndPoints: {
                GetAllRefundReason: "GetAllRefundReason",
                GetRefundReasonList: "GetRefundReasonList"
            }
        }
    }

    public static get CancelledReason() {
      return {
        Controller: "Order/CancelledReason",
        EndPoints: {
          GetAllCancelledReason: "GetAllCancelledReason",

        }
      }
    }

    public static get ReturnReason() {
      return {
        Controller: "Order/RefundReason",
        EndPoints: {
          GetAllRefundReason: "GetAllRefundReason",

        }
      }
    }


}
