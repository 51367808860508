
import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {MainSlider} from "@core/interface";
import UtilityFunctions from "@core/utilities/functions";
import {environment} from "@environments/environment";
import {isPlatformBrowser} from "@angular/common";
import { Carousel } from 'primeng/carousel';
import { GaLocalActionEnum } from '@core/enums/ga-local-action-enum';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-mtn-main-slider',
  templateUrl: './mtn-main-slider.component.html',
  styleUrls: ['./mtn-main-slider.component.scss']
})
export class MtnMainSliderComponent implements OnInit{
  sliderOptions: OwlOptions;
  innerWidth?: number;
  mobileScreen: boolean = false;
  @Input() sliders: Array<MainSlider> = [];
  sliderDots: any = [];

  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: any,
  private $gaService:GoogleAnalyticsService) {
    Carousel.prototype.onTouchMove = () => { };
    this.sliderOptions = {
      loop: true,
      autoplay: true,
      center: true,
      dots: true,
      autoplayTimeout: 3000,
      autoHeight: false,
      autoWidth: true,
      lazyLoad: true,
      autoplayHoverPause: true,

      navText: [
        '<em class="pi pi-angle-left white-color font-size-30"></em>',
        '<em class="pi pi-angle-right white-color font-size-30"></em>'
      ],
      responsive: {
        0: {
          items: 1,
          dots: true,
          nav: false,
        },
        600: {
          items: 1,
          dots: true,
          nav: false,
        },
        1000: {
          items: 1,
        }
      }
    };
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 768) {
        this.mobileScreen = true;
      } else {
        this.mobileScreen = false;
      }
    }
    this.sliders.map((x: any, index: number) => {
      this.sliderDots.push({
        index,
        selected: index === 0
      })
    })
  }
 routeToCTA(banner:any,url: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.$gaService.event(GaLocalActionEnum.CLICK_ON_BANNERS, '', 'BANNERS_ON_HOMEPAGE', 1, true, {
        bannerId: banner.promotionId || url.split('/').pop(),
        redirectPage: url
      });
      if(banner.promotionId){
        if(banner.CTALink) {
          const cta = banner.CTALink.replace(/promotions\//g, "promotion/")
          if(!banner.CTALink.includes('http://') && !banner.CTALink.includes('https://')){
 window.location.href = 'https://'+cta;
          } else {
            window.location.href = cta;
          }
        } else {
          let tempurl=' https://'+environment.marketPlaceHostName+'/promotion/'+banner.promotionId;
          window.location.href = tempurl;
        }
      }
      else {
         window.location.href = url;
      }
    }
  }
  getBannerImages(url: string) {
    return UtilityFunctions.verifyImageURL(url, environment.apiEndPoint);
  }
  changeSliderDot(event: any) {
    this.sliderDots.forEach((obj: any) => {
      obj.selected = obj.index == event.page;
    });
  }

}

