import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {MainDataService, AppDataService, HomeService} from "./";
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  public tenant = new Subject();
  public header = new Subject();
  public isAllowedCached : any = {
    isLandingPage: false,
    isHotDeals: false,
    isNewDeals: false,
    isNewArrivals: false,
    isGlobal:false
  }
  constructor(private mainDataService: MainDataService,
              private appDataService: AppDataService,
              private homeService: HomeService) {
  }

  setTenant(tenantId: any) {
    this.appDataService.clearAppData();
    this.tenant.next(tenantId);
  }

  getTenant() {
    return this.tenant;
  }

  setHeader(tenantId: any) {
    this.header.next(tenantId);
  }

  getHeader() {
    return this.header;
  }

  async getAppConfigurationData(isMobile = false): Promise<any> {

    if (!await this.shouldGetAppDataCache()) {
      if(this.isAllowedCached.isLandingPage){
        const referer: any = environment.referer
        const allAppConfiguration = this.mainDataService.getAllAppConfigurations(referer).toPromise();
        let res: any = await Promise.all([
          allAppConfiguration
        ]);
        // below condition for initial data is because we are expecting an object but getting array from backend
        this.appDataService.initialData = Array.isArray(res[0].data.initialData) ? res[0].data.initialData[0] : res[0].data.initialData
        this.appDataService.configuration = res[0].data.allConfiguration
        this.appDataService.tenants = res[0].data.allCountryTenants
        this.appDataService.categories = res[0].data.allCategory
        this.appDataService.showRoomConfiguration = res[0].data.tenantShowRoomConfiguration

        const appDataCache = {
          initialData: Array.isArray(res[0].data.initialData) ? res[0].data.initialData[0] : res[0].data.initialData,
          configuration: res[0].data.allConfiguration,
          tenants: res[0].data.allCountryTenants,
          categories: res[0].data.allCategory,
          showRoomConfiguration: res[0].data.tenantShowRoomConfiguration,
        }
        // localStorage.setItem('appData_expiry', (new Date).getTime().toString())
        localStorage.setItem('appData_cache', JSON.stringify(appDataCache))
      }else {
        let appDataCache: any = localStorage.getItem('appData_cache') ?? ''
        if (!appDataCache || appDataCache === '') {
          localStorage.removeItem('apiDataVersion')
          localStorage.removeItem('appData_cache')
          await this.getAppConfigurationData()
        } else {
          appDataCache = JSON.parse(appDataCache)

          this.appDataService.initialData = appDataCache.initialData
          this.appDataService.configuration = appDataCache.configuration
          this.appDataService.tenants = appDataCache.tenants
          this.appDataService.categories = appDataCache.categories
          this.appDataService.showRoomConfiguration = appDataCache.showRoomConfiguration
        }
      }


    }


  }

  async getConfigurationWithoutCache(isMobile: boolean) {
    const layoutTemplatePromise = this.mainDataService.getLayoutTemplate(isMobile).toPromise();
    let res: any = await Promise.all([
      layoutTemplatePromise
    ]);
    this.appDataService.layoutTemplate = res[0].data

  }

  shouldGetAppDataCache(): Promise<any> {
    return new Promise((resolve, reject) => {
      const tenantId: string = localStorage.getItem('tenantId') ?? '';
      this.homeService.getAppDataVersion(tenantId).subscribe({
        next: (version: any) => {
          let apiDataVersion : any = localStorage.getItem('apiDataVersion');
          if(!apiDataVersion || apiDataVersion === 'null' || apiDataVersion[0] === 'v'){
            localStorage.setItem('apiDataVersion', JSON.stringify(version));
            this.isAllowedCached.isLandingPage = true;
            this.isAllowedCached.isBestSeller = true;
            this.isAllowedCached.isNewArrivals = true;
            this.isAllowedCached.isHotDeals  = true;
            this.isAllowedCached.isGlobal = true;
            resolve(false)
            return false
          }else{
            let versionList = JSON.parse(localStorage.getItem('apiDataVersion') ?? '');
            if(version.find((item: any) => item.key === 'ApiDataVersion')?.value != versionList.find((item: any) => item.key === 'ApiDataVersion')?.value){
              this.isAllowedCached.isLandingPage = true;
            }
            if(!(versionList.find((item: any) => item.key === 'BestSeller')) || (version.find((item: any) => item.key === 'BestSeller')?.value != versionList.find((item: any) => item.key === 'BestSeller')?.value)){
              this.isAllowedCached.isBestSeller = true;
            }
            if(!(versionList.find((item: any) => item.key === 'NewArrivals')) || (version.find((item: any) => item.key === 'NewArrivals')?.value != versionList.find((item: any) => item.key === 'NewArrivals')?.value))
            {
              this.isAllowedCached.isNewArrivals = true;
            }
            if(!(versionList.find((item: any) => item.key === 'HotDeals')) || (version.find((item: any) => item.key === 'HotDeals')?.value != versionList.find((item: any) => item.key === 'HotDeals')?.value)){
              this.isAllowedCached.isHotDeals = true;
            }
            if(!(versionList.find((item: any) => item.key === 'Global')) || (version.find((item: any) => item.key === 'Global')?.value != versionList.find((item: any) => item.key === 'HotDeals')?.value)){
              this.isAllowedCached.isGlobal = true;
            }
            localStorage.setItem('apiDataVersion', JSON.stringify(version));
            resolve(false)
            return false
          }
        },
        error: () => {
          resolve(false)
          return false;
        }
      })
    })

  }
}
