import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BehaviorSubject } from 'rxjs';


@Injectable({
 providedIn: 'root'
})

export class CustomGAService {
  private dataSource = new BehaviorSubject<any>(null);
  currentData = this.dataSource.asObservable();

    constructor(private $gaService:GoogleAnalyticsService){
    
    }
    getCartItems(products:any[]){
      const cartProducts =  products.reduce((acc, item,index)=>{
        let product = {
          "item_id": "", 
          "item_name": "",
          "discount": 0, 
          "index": 0, 
          "item_brand": "", 
          "item_category": "", 
          "item_category2": "", 
          "item_category3": "", 
          "price": 0,
          "quantity":0
        };
        product.price = item.specProductDetails.salePriceValue || item.price,
        product.item_id = item.specProductDetails?.skuAutoGenerated || item.specProductDetails.sku,
        product.index = index,
        product.item_name = item.productName,
        product.discount = item.specProductDetails.salePriceValue && (item.price -  item.specProductDetails.salePriceValue),
        product.quantity = item.quantity
        product.item_category = item.categoryName
        acc.cart.push(product)
        acc.totalPrice += (item.specProductDetails.salePriceValue || product.price ) * product.quantity;
        return acc
      },{ cart: [], totalPrice: 0 })
      return cartProducts
    }
   productWithVarienceLog(selectedVariant:any,productDetails:any,event:string){
    const  price =  selectedVariant?.price || productDetails?.specProductDetails?.price 
    const  salePrice = selectedVariant?.salePrice || productDetails?.specProductDetails?.salePrice
    const  discount = salePrice && (price - salePrice)
      this.$gaService.event(event, 'Products',event,1,true,
      {
          "currency": productDetails.currencyCode, // Currency code (ISO 4217 standard) - Mandatory
          "value": price || salePrice, // Total item value after discounts - Mandatory
          "items": [
            {
              "item_id": selectedVariant?.skuAutoGenerated || productDetails?.specProductDetails?.skuAutoGenerated || productDetails.specProductDetails.sku, // Product SKU - Mandatory
              "item_name": productDetails.productName || productDetails.name, // Product Name - Mandatory (corrected spelling)
              "discount": discount, // Discount value associated with the item - Optional
              "index": 0, // The index/position of the item in a list - Optional
              "item_brand": "", // Item brand - Optional
              "item_category": productDetails.categoryName, // Item category level 1 - Corrected spelling
              "item_category2": "", // Item category level 2 - Optional
              "item_category3": "", // Item category level 3 - Optional
              "price": price || salePrice, // Unit price of the item - Optional
              "quantity":1
            }
          ]
        })
   }
   
  addToCartEvent(cartProduct:any,productDetails:any){ // when customer add product to card 
        this.$gaService.event("add_to_cart", 'Products','add_to_cart',1,true,
        {
            "currency": productDetails.currencyCode, // Replace with the appropriate currency code
            "value":cartProduct.specProductDetails?.salePrice || cartProduct.specProductDetails?.price || cartProduct.price, // Total price after sale * quantity
            "items": [
              {
                "item_id": cartProduct.skuAutoGenerated || cartProduct.sku, // Product SKU
                "item_name": productDetails.name || productDetails.productName, // Product name
                "discount": cartProduct.salePrice && (cartProduct.price - cartProduct.salePrice), // Discount value (optional)
                "index": 0, // Item position in list (optional)
                "item_brand": "", // Brand name (optional)
                "item_category": productDetails.categoryName, // Item category level 1 (corrected spelling)
                "item_category2": "", // Item category level 2
                "item_category3": "", // Item category level 3
                "price": cartProduct.specProductDetails?.salePrice || cartProduct.specProductDetails?.price || cartProduct.price, // Unit price
                "quantity": 1 // Quantity of items
              }
            ]
          })  
    } 
    viewItemEvent(selectedVariant:any,productDetails:any){ // when customer open the product details
      // const brand = productDetails.productAttributeValues?.find(item.)
      this.productWithVarienceLog(selectedVariant,productDetails,"view_item")
        
    }
    addToWishlistEvent(productDetails:any,selectedVariant?:any){ // when customer add to wishlist
      this.productWithVarienceLog(selectedVariant,productDetails,"add_to_wishlist")
}

removeFromCartEvent(product:any){ // Remove from cart 
    this.$gaService.event("remove_from_cart", 'Products','remove_from_cart',1,true,
    {
        "currency": product.currencyCode, // Currency code (ISO 4217 standard) - Mandatory
        "value": product.specProductDetails.salePrice || product.price, // Total item value after discounts - Mandatory
        "items": [
          {
            "item_id": product?.specProductDetails?.skuAutoGenerated, // Product SKU - Mandatory
            "item_name": product?.productName, // Product Name - Mandatory (corrected spelling)
            "discount": product.specProductDetails.salePriceValue && (product.price -  product.specProductDetails.salePriceValue), // Discount value associated with the item - Optional
            "index": 0, // The index/position of the item in a list - Optional
            "item_brand": "", // Item brand - Optional
            "item_category": "", // Item category level 1 - Corrected spelling
            "item_category2": "", // Item category level 2 - Optional
            "item_category3": "", // Item category level 3 - Optional
            "price": product.price, // Unit price of the item - Optional
            "quantity":product.quantity
          }
        ]
      })
}
viewCartEvent(products:any[]){ // View Cart 
 const cartProducts = this.getCartItems(products)
    this.$gaService.event("view_cart", 'Products','view_cart',1,true,
    {
        "currency":cartProducts.cart[0].currencyCode, // Currency code (ISO 4217 standard) - Mandatory
        "value": cartProducts.totalPrice, // Total item value after discounts - Mandatory
        "items":cartProducts.cart
      })
}

beginPurchaseEvent (products:any[]){ // Begin Purchase 
  const cartProducts = this.getCartItems(products)
    this.$gaService.event("begin_checkout", 'Checkout','begin_checkout',1,true,
    {
      "currency":cartProducts.cart[0].currencyCode, // Currency code (ISO 4217 standard) - Mandatory
      "value": cartProducts.totalPrice, // Total item value after discounts - Mandatory
      "items":cartProducts.cart
    })
}
addShippingInfoEvent (products:any[],shippingTier:string){ // Add shipping info  
    const cartProducts = this.getCartItems(products)
    this.$gaService.event("add_shipping_info", 'Checkout','add_shipping_info',1,true,
    {
      "currency":cartProducts.cart[0].currencyCode, // Currency code (ISO 4217 standard) - Mandatory
      "value": cartProducts.totalPrice, // Total item value after discounts - Mandatory
      "shipping_tier":shippingTier,
      "items":cartProducts.cart
    })
}
addPaymentInfoEvent (products:any[],paymentType:string){ // Add shipping info  
  const cartProducts = this.getCartItems(products)
  this.$gaService.event("add_payment_info", 'Checkout','add_payment_info',1,true,
  {
    "currency":cartProducts.cart[0].currencyCode, // Currency code (ISO 4217 standard) - Mandatory
    "value": cartProducts.totalPrice, // Total item value after discounts - Mandatory
    "payment_type":paymentType,
    "items":cartProducts.cart
  })
}
purchaseEvent (orderDetails:any,transactionId:string,shipmentFee:number,currencyCode:string){ // Purchase
 const cartProducts = this.getCartItems(orderDetails)

    this.$gaService.event("purchase", 'Checkout','purchase',1,true,
    {
        "transaction_id": transactionId, // Transaction ID - Mandatory
        "value":cartProducts.totalPrice, // Sum of (price * quantity) for all items
        "shipping": shipmentFee, // Shipping cost - Mandatory
        "currency": currencyCode, // Currency code (ISO 4217 standard) - Mandatory
        // "coupon": "SUMMER_SALE", // Optional coupon code
        "items": cartProducts.cart
      }
      )
}

viewItemListEvent(renderedProduct:any,itemListName:string) { //View item list event: when customer finds any product list like home, search results, or categories’ products list. 
  let product = {
    "item_id": (renderedProduct?.specProductDetails?.skuAutoGenerated ?  renderedProduct?.specProductDetails?.skuAutoGenerated: renderedProduct['skuAutoGenerated']) || renderedProduct.sku || renderedProduct.productId, 
    "item_name": renderedProduct.productName ? renderedProduct.productName : renderedProduct.name ,
    "discount": 0, 
    "index": 0, 
    "item_brand": "", 
    "item_category": renderedProduct.categoryName, 
    "item_category2": "", 
    "item_category3": "", 
    "price": renderedProduct.specProductDetails?.salePriceValue || renderedProduct.salePriceValue || renderedProduct.price || renderedProduct.priceValue,
  };
  let products =[]
  products.push(product)
  this.$gaService.event("view_item_list", 'Products','view_item_list',1,true,
  {
     item_list_id: itemListName, // The ID of the list in which the item was presented to the user. 
     item_list_name: itemListName, // The name of the list in which the item was presented to the user. 
     items: products
}); 

 
}
              
}