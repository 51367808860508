import {ChangeDetectorRef, Component, HostListener, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {environment} from '@environments/environment';
import {AgeConsent, Category, Currency, Product, ProductBadge} from '@core/interface';
import {
  ProductService,
  StoreService,
  ReviewsService,
  GuidGenerator,
  CartService,
  UserService,
  AuthTokenService, DetailsService, PermissionService, MainDataService
} from '@core/services';
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {CookieService} from "ngx-cookie-service";
import UtilityFunctions from "@core/utilities/functions";
import {GaActionEnum, GoogleAnalyticsService} from "ngx-google-analytics";
import {isPlatformBrowser} from "@angular/common";
import {GaLocalActionEnum} from "@core/enums/ga-local-action-enum";
import { CustomGAService } from '@core/services/custom-GA.service';

@Component({
  selector: 'app-mtn-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  baseUrl: string;
  proRate: number | undefined;
  proCount: number | undefined;
  @Input() isDragging: boolean = false;

  @Input() product: Product = {} as Product;
  @Input() currency: Currency = {} as Currency;
  @Input() liveStreamCardFlag: boolean = false;
  @Input() categoryName?:string =''
  rating: any = 5;
  scConfig: boolean = false
  decimalValue: number = 0;
  categoryId: any;
  category!: Category;
  currencyCode: string = '';
  disableCent: any;
  cartId: any;
  authToken: any;
  selectedVariant: any;
  isLiked: boolean= false;
  displayNotifyModal : boolean = false;
  displaySuccessModal: boolean = false;
  successTitleMessage: string = '';
  successBodyMessage : string = '';
  profile: any;
  isShowNotifyFeature: boolean = false;
  isLayoutTemplate: boolean = false;
  screenWidth:any=window.innerWidth;
  isMobileView: boolean =this.screenWidth <= 786;
  displayAgeConsentModal: boolean = false;
  displayEligableModal: boolean = false;
  restrictionAge: number;

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth
    }
    this.screenWidth = event.target.innerWidth;
    if (this.screenWidth <= 768) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  isEmailExist : boolean = false;
  topBadge: ProductBadge = {
    name: '',
    className: '',
    translatedName: '',
    classNameMobile:''
  }
  bottomBadge: ProductBadge = {
    name: '',
    className: '',
    translatedName: ''
  }
  tagName:any=GaLocalActionEnum;
  userDetails: any;
  isGoogleAnalytics: boolean = false;

  showMobileCartClicked: boolean = false;
  quantityInCart: number = 0;
  isMobileTemplate:boolean=false;
  type: string;
  constructor(private router: Router,
              @Inject(PLATFORM_ID) private platformId: any,
              private reviewsService: ReviewsService,
              private store: StoreService,
              private cartService: CartService,
              private userService: UserService,
              private messageService: MessageService,
              private translate: TranslateService,
              private authTokenService: AuthTokenService,
              private cookieService: CookieService,
              private detailsService: DetailsService,
              private productService: ProductService,
              private permissionService: PermissionService,
              private mainDataService: MainDataService,
              private cd: ChangeDetectorRef,
              private _GACustomEvents:CustomGAService,
              private $gaService: GoogleAnalyticsService) {
    this.scConfig = environment.isStoreCloud;
    this.isGoogleAnalytics = this.permissionService.hasPermission('Google Analytics');
    this.isLayoutTemplate = this.permissionService.hasPermission('Layout-Template');
    this.isMobileTemplate = this.permissionService.hasPermission('Mobile-Layout')
    this.disableCent = localStorage.getItem('DisableCents');
    this.baseUrl = environment.apiEndPoint + "/";
    let value = localStorage.getItem('decimalValue');
    let parsedValue = value?.replace(/"/g, '');
    if (parsedValue) {
      let number = parseInt(parsedValue);
      if (!Number.isNaN(number))
        this.decimalValue = number;
    }
  }

  ngOnInit() {
    this.profile = localStorage.getItem('profile');
    this.userDetails = this.store.get('profile');
    this.isShowNotifyFeature = this.permissionService.hasPermission('Notify-Me');
    this.getCartId()

    let currency = localStorage.getItem('currency')?.toString();
    if (currency)
      this.currencyCode = currency;

    if(this.product) {
      this.isLiked = this.product.isLiked
      this.assignCartQuantity();
      if(this.product.proSchedulingId) {
        this.topBadge = {
          name: 'promo',
          translatedName: 'Promo',
          className: 'mtn-product-card__grey-label',
          classNameMobile:'mtn-product-card-mobile__grey-label'
        }
      } else {
        // assign the top badge
        // verify if product is in stock or not
        if(this.product.soldOut) {
          this.topBadge = {
            name: 'sold-out',
            translatedName: 'productDetails.details.outOfStock',
            className: 'mtn-product-card__grey-label',
            classNameMobile:'mtn-product-card-mobile__grey-label'

          }
        } else {
          // top badge for sale
          if(this.product.salePercent) {
            this.topBadge = {
              name: 'sale',
              translatedName: 'productDetails.details.sale',
              className: 'mtn-product-card__green-label',
              classNameMobile:'mtn-product-card-mobile__green-label'

            }
          }
          // top badge for hot deals
          if(!this.isBadgeExists(['sold-out', 'sale'], 'top') && this.product.isHot) {
            this.topBadge = {
              name: 'hot-deal',
              translatedName: 'productDetails.details.hot',
              className: 'mtn-product-card__red-label',
              classNameMobile:'mtn-product-card-mobile__red-label'

            }
          }
          // top badge for best seller
         else if(!this.isBadgeExists(['sold-out', 'sale', 'hot-deal'], 'top') && this.product.isBest) {
            this.topBadge = {
              name: 'best-seller',
              translatedName: 'productDetails.details.bestSeller',
              className: 'mtn-product-card__blue-label',
              classNameMobile:'mtn-product-card-mobile__blue-label'

            }
          }
          // top badge for new arrival
         else  if(!this.isBadgeExists(['sold-out', 'sale', 'hot-deal', 'best-seller'], 'top') && this.product.isNew) {
            this.topBadge = {
              name: 'new-arrival',
              translatedName: 'productDetails.details.newArrivals',
              className: 'mtn-product-card__light-blue-label',
              classNameMobile:'mtn-product-card-mobile__light-blue-label'
            }
          }
      }


        // assign bottom badge
        // bottom badge for hot deals
        if(!this.isBadgeExists(['hot-deal'], 'top') &&
          !this.isBadgeExists(['hot-deal'], 'bottom') &&
          this.product.isHot) {
          this.bottomBadge = {
            name: 'hot-deal',
            translatedName: 'productDetails.details.hot',
            className: 'mtn-product-card__red-label'
          }
        }
        // bottom badge for best seller
        else if(!this.isBadgeExists(['best-seller'], 'top') &&
          !this.isBadgeExists(['best-seller'], 'bottom') &&
          this.product.isBest) {
          this.bottomBadge = {
            name: 'best-seller',
            translatedName: 'productDetails.details.bestSeller',
            className: 'mtn-product-card__blue-label'
          }
        }
        // bottom badge for new arrival
        else if(!this.isBadgeExists([ 'new-arrival'], 'top') &&
          !this.isBadgeExists(['new-arrival'], 'bottom') &&
          this.product.isNew) {
          this.bottomBadge = {
            name: 'new-arrival',
            translatedName: 'productDetails.details.newArrivals',
            className: 'mtn-product-card__light-blue-label'
          }
        }
      }
    }
  }
ngOnChanges(){
  if(this.categoryName){
    this.product['categoryName'] = this.categoryName

  }
}

  assignCartQuantity() {
    this.mainDataService.getCartItemsData().subscribe((res: any) => {
      if(res && res.length > 0) {
        const productFound = res.find((cartProduct: any) => cartProduct.specsProductId === this.product.specProductId)
        if(productFound) {
          this.quantityInCart = productFound.quantity
          this.cd.detectChanges()
        }
      }
    });
  }
  isBadgeExists(presentBadges: string[], badgePosition: string) {
    if(badgePosition === 'top') {
      return presentBadges.some(string => string === this.topBadge.name);
    }
  }

  productDetails(productId?: any): void {

    if (!this.isDragging && !productId) {

      this.router.navigate(['product', this.product.specsProductId, this.product.channelId], {
        queryParams: {
          tenantId: localStorage.getItem("tenantId"),
          lang: localStorage.getItem("lang")
        },
        queryParamsHandling: 'merge',
      })
    } else if (!this.isDragging && productId) {
      if(this.liveStreamCardFlag == false){
      this.router.navigate(['product', productId, this.product.channelId], {
        queryParams: {
          tenantId: localStorage.getItem("tenantId"),
          lang: localStorage.getItem("lang")
        },
        queryParamsHandling: 'merge',
      })
      }else{
        const baseUrl = this.router.createUrlTree(['product', productId, this.product.channelId], {
          queryParams: {
            tenantId: localStorage.getItem('tenantId'),
            lang: localStorage.getItem('lang'),
          },
        }).toString();

        const externalUrl = `${window.location.origin}/storecloud${baseUrl}`;
        window.open(externalUrl, '_blank');
      }

    }
  }

  getProductImages(url: string) {
    if(url=="")
    { url == this.product.masterImageUrl ? this.product.masterImageUrl : this.product?.thumbnailImages?.length ? this.product?.thumbnailImages[0] : null }
    if(this.product.channelId == 2) return url;
    return UtilityFunctions.verifyImageURL(url, environment.apiEndPoint);
  }

  errorHandler(event: any) {
    if (environment.isStoreCloud) {
      event.target.src = "assets/images/placeholder.png";

    } else {
      event.target.src = "assets/images/mtn-alt.png";
    }
  }

  mainAddToCartButton(type: string, event: any, action: string = 'add') {
    event.stopPropagation();
    if(this.quantityInCart > 0) {
      this.showMobileCartClicked = true;
      setTimeout(() => {
        this.showMobileCartClicked = false;
      },5000)
    } else {
      this.addItemToCart(type, event, action)
    }
  }
  addItemToCart(type: string, event: any, action: string = 'add') {
    event.stopPropagation();
    if (this.product.soldOut) {
          return;
    }
    let isTrigger = true;
    this.mainDataService.getCartItemsData().subscribe((res: any) => {
      if (isTrigger) {
        if (res && res.length > 0) {
          let cartProduct: any = res.find((x: any) => x.specsProductId === this.product.specProductId);
          if (cartProduct) {
            if (this.product.itemPerCustomer && (cartProduct.quantity + 1) > this.product.itemPerCustomer) {
              this.messageService.add({
                severity: 'error',
                summary: this.translate.instant('ErrorMessages.itemPerCustomerError') + this.product.itemPerCustomer + this.translate.instant('ErrorMessages.itemPerCustomerErrorNext')
              });
              isTrigger = false
              return;
            }
            else{
              // handling quantity for update Cart
              if(action === 'add'){
                if(this.product.proSchedulingId){
                  let promotionCartProduct = res.find((x: any) => x.proSchedulingId === this.product.proSchedulingId);
                  if(promotionCartProduct){
                    cartProduct.quantity = promotionCartProduct.quantity + 1;
                  }else{
                    cartProduct.quantity = 1;
                  }
                }else{
                  cartProduct.quantity+=1;
                }
                this.quantityInCart=cartProduct.quantity;
              }
              else {
                cartProduct.quantity-=1;
                this.quantityInCart=cartProduct.quantity;
                if(this.quantityInCart==0) this.showMobileCartClicked=false;
                if(this.quantityInCart < 0) {
                  this.quantityInCart = 0;
                  cartProduct.quantity=0;
                }
              }
            }
            // update cart
            isTrigger = false
            this.updateCart(cartProduct,type, action)
          }
          else{
            // handling quantity for create cart when cart is not empty
            this.quantityInCart=1;
            isTrigger = false
            // create cart
            this.addToCart(type, action);

          }
        }
        else{
          // handling quantity for create cart when cart is empty
          this.quantityInCart=1;
          isTrigger = false
          // create cart
          this.addToCart(type, action);
        }
      }

    });
  }
  addToCart(type: string, action: string){
    const sessionId = this.getSessionId();
    const reqObj = {
      productId:this.product.productId,
      quantity: this.quantityInCart,
      sessionId:sessionId,
      shopId: this.product.shopId,
      specsProductId: this.product.specProductId,
      priceId:  this.product.priceId ?? 0,
      channelId: this.product.channelId,
      proSchedulingId: this.product.proSchedulingId
    };
    this.type = type;
    this.cartService.addToCart(reqObj).subscribe({
      next: (res: any) => {
        if(!res.data?.userFailedProductEligibility) {
          this.updateCartInfo(res, type, this.product, sessionId, action)
        } else {
          this.restrictionAge = res.data.productAgeRestriction;
          this.displayAgeConsentModal = true;
        }
      },
      error: (err: any) => {
        this.handleError(err.message);
      }
    });
  }
  updateCart(cartProduct:any,type:string, action: string){
    const sessionId = this.getSessionId();
    this.type = type;
    cartProduct.proSchedulingId = this.product.proSchedulingId;
    cartProduct.specProductDetails.proSchedulingId = this.product.proSchedulingId;
    this.cartService.updateCart(cartProduct).subscribe({
      next: (res: any) => {
        if(!res.data?.userFailedProductEligibility) {
          this.updateCartInfo(res, type, cartProduct, sessionId, action)
        } else {
          this.restrictionAge = res.data.productAgeRestriction;
          this.displayAgeConsentModal = true;
        }
      },
      error: (err: any) => {
        this.handleError(err.message);
      }
    });
  }

  addItemToCartOld(type: string, event: any, action: string = 'add') {
    event.stopPropagation();

    if (this.product.soldOut) {
      return;
    }

    let isTrigger = true
    this.mainDataService.getCartItemsData().subscribe((res: any) => {
      if(isTrigger) {
        let shouldAddToCart = false
        let alreadyAddedToCart = false
        if(res && res.length > 0) {
          res.map((x: any) => {
            if(action === 'add') {
              this.quantityInCart += 1;
            }
            else {
              this.quantityInCart -= 1
              if(this.quantityInCart < 0) this.quantityInCart = 0;
            };
            if(x.specsProductId === this.product.specProductId) {
              alreadyAddedToCart = true
              // @ts-ignore
              if(this.product.itemPerCustomer && this.quantityInCart > this.product.itemPerCustomer) {
                shouldAddToCart = false
              } else {
                shouldAddToCart = true
              }
            } else {
              shouldAddToCart = true
            }
          })
        } else {
          this.quantityInCart=1;
          shouldAddToCart = true
        }

        if(!shouldAddToCart) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('ErrorMessages.itemPerCustomerError')+ this.product.itemPerCustomer + this.translate.instant('ErrorMessages.itemPerCustomerErrorNext')
          });
        } else {
          const sessionId = this.getSessionId();

          const cartProduct = { ...this.product, quantity: this.quantityInCart, cartId: this.cartId, sessionId };

          const reqObj = {
            productId: cartProduct.productId,
            quantity: cartProduct.quantity,
            sessionId,
            shopId: cartProduct.shopId,
            specsProductId: cartProduct.specProductId,
            cartId: this.cartId,
            priceId: cartProduct.priceId ?? 0,
            channelId: cartProduct.channelId,
            proSchedulingId: this.product.proSchedulingId
          };
          isTrigger = false
          if(alreadyAddedToCart) {
            this.cartService.updateCart(cartProduct).subscribe({
              next: (res: any) => {
                this.updateCartInfo(res, type, cartProduct, sessionId, action)
              },
              error: (err: any) => {
                this.handleError(err.message);
              }
            });
          } else {
            this.cartService.addToCart(reqObj).subscribe({
              next: (res: any) => {
                this.updateCartInfo(res, type, cartProduct, sessionId, action)
              },
              error: (err: any) => {
                this.handleError(err.message);
              }
            });
          }

        }
      }

    })


  }

  updateCartInfo(res: any, type: string, cartProduct: any, sessionId: string, action: string){
    if (res?.success) {
      if(res.data.cartItems.length>0) {
        localStorage.setItem("cartId", res.data.cartItems[0].cartId);
      }
      if (type === 'addCart') {
        if(this.isGoogleAnalytics){
          this._GACustomEvents.addToCartEvent(cartProduct,this.product)
          this.$gaService.event(this.tagName.CLICK_ON_ADD_TO_CART,'product',
          'ADD_TO_CART',1,true,{
            "product_ID":cartProduct.productId,
            "product_name":cartProduct.productName,
            "product_SKU":cartProduct?.specProductDetails?.skuAutoGenerated,
            "seller_name":cartProduct?.sellerName,
            "user_ID":this.userDetails?this.userDetails.mobileNumber:'Un_Authenticated',
            "session_ID":sessionId,
            "shop_ID":cartProduct.shopId,
            "category_name": this.categoryName,
            "ip_Address": this.store.get('userIP'),
            "device_Type": this.store.get('deviceInfo')?.deviceType,
            "device_Id": this.store.get('deviceInfo')?.deviceId,
            "product_tags":this.product?.isBest ? 'Best Seller' : this.product?.isNew ? 'New Arrival' : this.product?.isHot ? 'Hot Deals':'',
           "promotion":cartProduct?.promotionName ? cartProduct?.promotionName : 'None',
          });
        }
        this.handleAddToCart(type, sessionId);
      }

      if (type === 'buyNow') {
        if(this.isGoogleAnalytics){
          this.$gaService.event(this.tagName.CLICK_ON_BUY_NOW,'product',
           'BUY_NOW',1,true,{
            "product_ID":cartProduct.productId,
            "product_name":cartProduct.productName,
            "product_SKU":cartProduct?.specProductDetails?.skuAutoGenerated,
            "seller_name":cartProduct?.sellerName,
            "user_ID":this.userDetails?this.userDetails.mobileNumber:'Un_Authenticated',
            "session_ID":sessionId,
            "shop_ID":cartProduct.shopId,
            "category_name": this.categoryName,
            "ip_Address": this.store.get('userIP'),
            "device_Type": this.store.get('deviceInfo')?.deviceType,
            "device_Id": this.store.get('deviceInfo')?.deviceId,
            "product_tags":this.product?.isBest ? 'Best Seller' : this.product?.isNew ? 'New Arrival' : this.product?.isHot ? 'Hot Deals':'None',
            "promotion":cartProduct?.promotionName ? cartProduct?.promotionName : 'None',
          });
        }
        this.router.navigate(['/cart']);
      }
      this.handleSuccess(action);
    }
    else{
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('ResponseMessages.cart'),
        detail: res.message
      });
    }
  }

  getSessionId(): string {
    let sessionId: string = localStorage.getItem('sessionId') ?? '';

    if (!sessionId) {
      sessionId = GuidGenerator.newGuid();
      localStorage.setItem('sessionId', sessionId);
    }

    return sessionId;
  }

  handleAddToCart(type: string, sessionId: string): void {
    const cartData: any = { sessionId };
    const applyTo = localStorage.getItem('apply-to');

    if (applyTo && applyTo !== '') {
      cartData['applyTo'] = applyTo;
    }

    this.cartService.getAllCart(cartData).subscribe({
      next: (data: any[]) => {
        const selectedProduct = data.find(product => product.specsProductId == this.product.specProductId)
        if(selectedProduct) {
          this.quantityInCart = selectedProduct.quantity
        }
        if(selectedProduct && this.quantityInCart>0) {
          this.showMobileCartClicked = true;
          setTimeout(() => {
            this.showMobileCartClicked = false;
          }, 5000)
        }
      },
      error: (error) => {
        console.error('Error fetching cart data', error);
      }
    });
  }

  handleError(message:string){
    this.messageService.add({
      severity: 'error',
      summary: this.translate.instant('ErrorMessages.fetchError'),
      detail: message
    });
  }

  handleSuccess(action: string = 'add'){
    if(action === 'minus') {
      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('ResponseMessages.cart'),
        detail: this.translate.instant('ResponseMessages.successfullyRemovedFromCart'),
      });
    } else {
      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('ResponseMessages.cart'),
        detail: this.translate.instant('ResponseMessages.successfullyAddedToCart')
      });
    }

  }

  getCartId() {
    this.store.subscription('cartProducts')
      .subscribe({
        next: (res: any) => {
          if (res.length > 0) {
            this.cartId = res[0].cartId;

            this.currencyCode = res[0].currencyCode;
          }
        },
        error: (err: any) => {
          console.error(err);
        }
      });
  }
  addToWishlist(event:any,product:any) {
    this.authTokenService.authTokenData.subscribe(
      (message: any) => (this.authToken = message)
    );
    if (!this.authToken) {
      this.authToken = this.cookieService.get('authToken');
    }
    if (!this.authToken) {
      this.router.navigate(['login'], { queryParams: { returnUrl: `/` } });
      return;
    }
    let specsProductId=product.specProductId;
    let flag;
    if(this.isLiked){
       flag = true;
    }else{
      flag=product.isLiked;
    }
    const obj = {
      specsProductId,
      flag,
      productId:product.productId,
      channelId: this.product.channelId,
    }
    this.detailsService.wishlistToggle(obj).subscribe({
      next: (res: any) => {
        if (res?.success) {
          this.isLiked = !this.isLiked;
          this.cd.detectChanges()
          if (this.isLiked) {
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('ResponseMessages.wishList'),
              detail: this.translate.instant(
                'ResponseMessages.successfullyAddedToWishList'
              ),
            });

          } else {
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('ResponseMessages.wishList'),
              detail: this.translate.instant(
                'ResponseMessages.successfullyRemovedToWishList'
              ),
            });

          }
        }
      },
      error: (err: any) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('ErrorMessages.fetchError'),
          detail: err.message,
        });
      },
    });
  }

  notifyMe(){
    this.displayNotifyModal = true;
    const profile = JSON.parse(localStorage.getItem('profile') ?? '')
    if(profile?.email){
      this.isEmailExist = true;
    }else{
      this.isEmailExist = false;
    }
  }
  onSubmitNotify(data : any){
    let reqObj : any = {}
    reqObj['email'] = data.email ? data.email : ''
    if(this.product.specProductId){
      reqObj['specProductId'] = this.product.specProductId
    }
    if(data.phone){
      let dialCode = data.phone.dialCode.substring(1,4);
      reqObj['phoneNumber'] = dialCode + data.phone.number.replace(/\s/g, '')
    }else{
      reqObj['phoneNumber'] = '';
    }
    this.productService.notifyMeProduct(reqObj).subscribe((res : any) => {
      if(res.success){
        this.successTitleMessage = this.translate.instant("notifyMeDetails.thanksForInterest")
        this.successBodyMessage = this.translate.instant("notifyMeDetails.notifyProductIsAvaialble")
        this.displaySuccessModal = true;
        this.displayNotifyModal =  false;
      }
    })
  }
  onCancel(){
    this.displaySuccessModal = false;
    this.displayNotifyModal =  false;
  }

  getSalePricePercent(salePrice: number) {
    return salePrice % 1 !== 0 ? salePrice.toFixed(2) : salePrice
  }
  getSalePrice(){
    return Number(this.product?.salePercent?.toFixed(0))
  }

  onSubmitConsent() {
    this.displayAgeConsentModal = false;
    const userProfile = localStorage.getItem("profile") || '';
    const userId = userProfile ? JSON.parse(userProfile)?.id : null;
    let data: AgeConsent = {
      sessionId: localStorage.getItem('sessionId') || '',
      MinimumAgeForProductEligibility: this.restrictionAge
    }

    userId ? data.userId = userId : ''

    const sessionId = this.getSessionId();
    const reqObj = {
      productId:this.product.productId,
      quantity: this.quantityInCart,
      sessionId:sessionId,
      shopId: this.product.shopId,
      specsProductId: this.product.specProductId,
      priceId:  this.product.priceId ?? 0,
      channelId: this.product.channelId,
    };

    this.userService.updateAgeConsent(data).subscribe({
      next: (res: any) => {
        // On user consenting to age restriction add the product to cart
        this.cartService.addToCart(reqObj).subscribe({
          next: (res: any) => {
            this.updateCartInfo(res, this.type, this.product, sessionId, 'add')
          },
          error: (err: any) => {
            this.handleError(err.message);
          }
        });
      },
      error: (err: any) => {
        this.handleError(err.message);
      }
    })
  }
  closeConsentModal() {
    this.displayEligableModal = true;
    this.displayAgeConsentModal = false;
  }
  closeEligableModal() {
    this.displayEligableModal = false;
  }
}
