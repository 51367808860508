import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-age-consent-modal',
  templateUrl: './age-consent-modal.component.html',
  styleUrls: ['./age-consent-modal.component.scss'],
})
export class AgeConsentModalComponent {
  @Input() displayModal: boolean = false;
  @Input() age: number = 10;
  @Output() submit = new EventEmitter();
  @Output() cancel = new EventEmitter();

  onSumbit() {
    this.submit.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
