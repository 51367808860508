import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {FeatureType} from '../interface';
import {castToHttpParams} from '../utilities';
import {AppDataService} from "@core/services/app-data.service";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private appDataService: AppDataService
  ) {
    this.baseUrl = `${environment.apiEndPoint}/Product`;
  }

  getCategoryProducts(categoryId: number,
                      topNumber: number,
                      IgnorePagination: boolean = true,
                      showLoader:boolean = false,
                      paginated= false,
                      currentPageNumber?:any
                      ): Observable<object> {

    let ShowOutOfStock = false
    const showRoomRecords = this.appDataService.showRoomConfiguration.records
    if(showRoomRecords.length) {
      const featureRecord = showRoomRecords.find((res: any) => res.showRoomTypeId == 3);
      if(featureRecord) {
        ShowOutOfStock = featureRecord.showOutOfStock;
      }
    }
    let endPoint2 = '/CategoryProduct/GetCategoriesParentAndChildProductsNew/';
    let url: string = `${this.baseUrl}${endPoint2}`
    url += paginated ? '?paginated=true' : '';
    if(url.includes('?')) {
      url += 'showLoader='+showLoader
    } else {
      url += '?showLoader='+showLoader
    }
    let data = {
      categoryId: Number(categoryId),

      CurrentPage: currentPageNumber??1,
      ProductStatus: 0,
      IgnorePagination: false,
      PageSize: 50,
      ShowOutOfStock
    }


    return this.http.get(`${url}`, {params: castToHttpParams(data)});

  }

  getCategoriesProducts(categoriesId: number[], topNumbers: number[])
    : Observable<object> {
    let url: string = `${this.baseUrl}/CategoryProduct/GetCategoryProductsByCategories`;


    url += "?";
    for (let categoryId of categoriesId) {
      url += "categoriesId=" + categoryId + "&";
    }
    for (let topNumber of topNumbers) {
      url += "topNumbers=" + topNumber + "&";
    }
    return this.http.get(`${url}`);
  }


  getProductDetails(id: number, channelId: any = '1',platformId:any='0'): Observable<object> {
    return this.http.get(`${this.baseUrl}/Product/ProductDetailsNew/${id}/${channelId}/${platformId}`);
  }

  getSizeGuidDetails(id: number): Observable<object> {
    return this.http.get(`${this.baseUrl}/Attribute/GetAttributeById/${id}`);
  }

  getProducts(id: number): Observable<object> {
    return this.http.get(`${this.baseUrl}/Product/GetProductById/productId=${id}`);
  }

  GetAllProductsByFeature(
    enumValue: FeatureType,
    topNumber: any = null,
    paginated: boolean = false,
    currentPageNumber?:any,
    pageSize: number = 50,
    showLoader: boolean = true,
    showOutOfStock: any = null,
    ignorePagination: boolean = false): Observable<object> {
    let url: string = `${this.baseUrl}/Product/GetAllProductsByFeatureNew`;
    let data: any = {
      CurrentPage: currentPageNumber,
      ProductStatus: 0,
      IgnorePagination: ignorePagination,
      PageSize: pageSize,
      paginated: paginated,
      FeatureType: enumValue,
      showLoader: showLoader
    }

    if(!data.IgnorePagination) data.PageSize = pageSize;
    if(topNumber !== null) data.topNumber = topNumber;
    if(showOutOfStock !== null) data.showOutOfStock = showOutOfStock;
    if(!ignorePagination){
      delete data.topNumber
    }
    return this.http.get(`${url}`,{params:castToHttpParams(data)});
  }
  GetAllProductsByPrmotion(
    promotionId: any,
    topNumber: number,
    paginated: boolean = false,
    currentPageNumber?:any,
    pageSize: number = 50,
    showLoader: boolean = true): Observable<object> {
    let url: string = `${this.baseUrl}/Promotions/GetAllProductsByPromotionName`;
    // url += "?paginated="+paginated + '&';
    // url += "FeatureType=" + enumValue + "&";
    // url += "showLoader=" + showLoader + "&";

    let data={
      PromotionName:promotionId.replace(/-/g, " "),
        CurrentPage: currentPageNumber,
        ProductStatus: 0,
        IgnorePagination: false,
        PageSize: pageSize,
        paginated: paginated,
        showLoader: showLoader
    }
    return this.http.get(`${url}`,{params:castToHttpParams(data)});
  }

  // FilterWithProductName(name: any,currentPage?:any,pageSize?:any): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/Product/GetAllProductsNew?paginated=true&SearchString=${name}&currentPage=${currentPage}&pageSize=${pageSize}`);
  //
  // }
  FilterWithSearchProductName(name: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/Product/GetAllProductsNew?SearchString=${name}&currentPage=1&pageSize=8`);

  }
  FilterWithProductName(name: any,currentPage?:any,pageSize?:any): Observable<any> {
    return this.http.get(`${this.baseUrl}/Product/GetAllProductsNew?paginated=true&SearchString=${name}&currentPage=${currentPage}&pageSize=${pageSize}`);

  }
  GetProductsByShop(shopId: number): Observable<object> {
    return this.http.get(`${this.baseUrl}/Product/GetProductsByShop/${shopId}`);
  }
  notifyMeProduct(params: any): Observable<object> {
    return this.http.post(`${environment.apiEndPoint}/product/product/notifyproduct`, params)
  }
  getAllTemplateFields(id: any): Observable<any> {
    const requestObj: any = {
      'IgnorePagination': true,
      'TemplateId': id,
      'currentPage': 1,
      'pageSize': 5
    };
    return this.http.get(`${environment.apiEndPoint}/Product/TemplateFields/GetAllTemplateFields?currentPage=` + requestObj.currentPage + `&pageSize=` + requestObj.pageSize +
      `&IgnorePagination=` + requestObj.IgnorePagination + `&TemplateId=` + requestObj.TemplateId).pipe(
      map((data) => {
        return data;
      })
    );
  }
  getAttributeById(id: any): Observable<any> {
    return this.http
      .get(
        `${environment.apiEndPoint}/Product/Attribute/GetAttributeById/${id}`,
      )
  }
}
