import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient, HttpParams} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {
  PaginatedResult,
  ResponseModel,
  CancelOrderReq,
  GetAllOrderDetailRes,
  GetAllOrderRes,
  GetAllRefundReasonRes,
  GetCustomerOrderReq,
  RefundOrderReq,
  RequestOrderRefundReq, ReturnOrder,
  IRejectOrder
} from '@core/interface';
import {castToHttpParams, EndPointsConfig} from '@core/utilities';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = `${environment.apiEndPoint}/Order`;

  }

  getAllOrders(): Observable<object> {
    return this.http.get(`${this.baseUrl}/Order/GetAllOrder`);
  }

  getOrder(id: number): Observable<object> {
    return this.http.get(`${this.baseUrl}/Order/GetOrderById?OrderId=${id}`);
  }

  getAllOrderDetail(id: number): Observable<ResponseModel<GetAllOrderDetailRes[]>> {

    let params = new HttpParams().set('id', id);
    return this.http.get<ResponseModel<GetAllOrderDetailRes[]>>(`${EndPointsConfig.ApiUrl.Url}/${EndPointsConfig.OrderDetails.Controller}/${EndPointsConfig.OrderDetails.EndPoints.GetAllOrderDetail}`, {params: params});

  }

  GetOrderWithPaymentsConfigurations(data: any): Observable<ResponseModel<GetAllOrderDetailRes[]>> {
    return this.http.post<ResponseModel<GetAllOrderDetailRes[]>>(`${this.baseUrl}/Order/GetOrderWithPaymentsConfigurations`, data);
  }


  createOrder(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/Order/CreateOrder`, data);
  }

  updateOrder(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/Order/UpdateOrder`, data);
  }
  checkAddressCityRegion(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}/Tenant/Address/CheckAddressCityRegion`, data);
  }
  getCustomerOrders(model: GetCustomerOrderReq): Observable<{ count: number, orders: GetAllOrderRes[]; }> {

    return this.http.get<ResponseModel<PaginatedResult<GetAllOrderRes>>>(`${EndPointsConfig.ApiUrl.Url}/${EndPointsConfig.Order.Controller}/${EndPointsConfig.Order.EndPoints.GetCustomerOrder}`, {params: castToHttpParams(model)})
      .pipe(map(res => {
        return {
          orders: res?.data?.records ? res?.data?.records : [],
          count: res?.data?.total ? res?.data?.total : 0
        };
      }));
  }

  cancelOrder(data: CancelOrderReq): Observable<ResponseModel<boolean>> {

    return this.http.post<ResponseModel<boolean>>(`${this.baseUrl}/Order/CancelOrder`, data);
  }

  refundOrder(model: RefundOrderReq): Observable<{ success: boolean; }> {
    return this.http.post<ResponseModel<boolean>>(`${EndPointsConfig.ApiUrl.Url}/${EndPointsConfig.Order.Controller}/${EndPointsConfig.Order.EndPoints.RefundOrder}`, model)
      .pipe(map((res: any) => {

        return {
          success: res?.success
        };
      }));
  }

  requestOrderRefund(model: RequestOrderRefundReq): Observable<{ success: boolean; }> {
    return this.http.post<ResponseModel<boolean>>(`${EndPointsConfig.ApiUrl.Url}/${EndPointsConfig.Order.Controller}/${EndPointsConfig.Order.EndPoints.RequestOrderRefund}`, model)
      .pipe(map((res: any) => {

        return {
          success: res?.success
        };
      }));
  }

  getRefundReasons(): Observable<{ reasons: GetAllRefundReasonRes[]; }> {
    let params = new HttpParams().set('PageSize', 20);

    return this.http.get<ResponseModel<PaginatedResult<GetAllRefundReasonRes>>>(`${EndPointsConfig.ApiUrl.Url}/${EndPointsConfig.RefundReason.Controller}/${EndPointsConfig.RefundReason.EndPoints.GetAllRefundReason}`, {params: params})
      .pipe(map(res => {
        return {
          reasons: res?.data?.records ? res?.data?.records : []
        };
      }));
  }

  getAllRefundReasons(): Observable<ResponseModel<GetAllRefundReasonRes[]>> {
    return this.http.get<ResponseModel<GetAllRefundReasonRes[]>>(`${EndPointsConfig.ApiUrl.Url}/${EndPointsConfig.RefundReason.Controller}/${EndPointsConfig.RefundReason.EndPoints.GetRefundReasonList}`);
  }

  updateOrderStatus(orderId: number): Observable<{ success: boolean; }> {
    return this.http.get<ResponseModel<boolean>>(`${EndPointsConfig.ApiUrl.Url}/${EndPointsConfig.Order.Controller}/${EndPointsConfig.Order.EndPoints.UpdateOrderStatus}/${orderId}`)
      .pipe(map((res: any) => {

        return {
          success: res?.success
        };
      }));
  }

  returnOrder(data: ReturnOrder): Observable<ResponseModel<boolean>> {

    return this.http.post<ResponseModel<boolean>>(`${this.baseUrl}/Order/ConsumerRequestRefund`, data);
  }

  applyPromoCode(data: {OrderId: number, PromoCode: any, PaymentType: string}): Observable<ResponseModel<boolean>> {
    return this.http.post<ResponseModel<boolean>>(`${this.baseUrl}/Order/ApplyPromoCode`, data);
  }

  getOrderDiscount(id: number): Observable<ResponseModel<any>> {
    let params = new HttpParams().set('orderId', id);
    return this.http.get<ResponseModel<any>>(`${this.baseUrl}/Order/GetOrderDiscountValue`, {params: params});
  }
  
  removePromoCode(data: {OrderId: number, PromoCode: any}): Observable<ResponseModel<boolean>> {
    return this.http.post<ResponseModel<boolean>>(`${this.baseUrl}/Order/RemovePromoCode`, data);
  }

rejectAllOrder (data:IRejectOrder){
  return this.http.post<ResponseModel<boolean>>(`${this.baseUrl}/Order/CancelOrder`,data ) 
}

}
