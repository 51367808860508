import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthTokenService} from './token.service';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public storeData: any;
  public localStoreNames: Array<string>;
  private readonly store: any;

  constructor(private authTokenService: AuthTokenService, private cookieService: CookieService) {
    this.storeData = {};
    this.store = {};
    this.localStoreNames = [];
  }

  createNewStore(storeName: string, initData: any, localStore: boolean): void {
    this.store[storeName] = new BehaviorSubject<any>(initData);
    if (localStore) {
      const data = this.getFromLocalStorage(storeName) || initData;
      this.localStoreNames.push(storeName);
      this.set(storeName, data);
    } else {
      this.set(storeName, initData);
    }
  }

  subscription(storeName: string): Observable<any> {
    if (storeName == "authToken") {
      let token: any;
      this.authTokenService.authTokenData.subscribe(message => token = message);
      if (!token) {
        this.cookieService.get('authToken');
        return token;
      }
      return token;
    } else {
      return this.store[storeName];
    }

  }

  set(storeName: string, data: any): void {

    const currentStore: BehaviorSubject<any> = this.store[storeName];
    if (currentStore) {

      if (this.localStoreNames.includes(storeName)) {

        this.setLocalStorage(storeName, data);
        this.update(storeName, data);
        this.fireChanges(storeName, currentStore);
      } else {

        this.update(storeName, data);
        this.fireChanges(storeName, currentStore);
      }

    } else {
      console.error(`${storeName} is not assign to store please check it`);
    }
  }

  update(storeName: string, data: any): void {
    this.storeData[storeName] = data;
  }

  get(key: string): any {
    return this.storeData[key];
  }

  private fireChanges(storeName: string, currentStore: BehaviorSubject<any>): void {
    currentStore.next(this.storeData[storeName]);
  }

  private isJson(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  private setLocalStorage(key: string, data: any): any {
    let stringData = '';
    if (data) {
      if (typeof data === 'string') {
        stringData = data;
      } else {
        stringData = JSON.stringify(data);
      }
    }
    return localStorage.setItem(key, stringData);
  }

   getFromLocalStorage(key: string): any {
    const data = localStorage.getItem(key) ?? '';
    if (this.isJson(data)) {
      return JSON.parse(data) || null;
    } else {
      return data || null;
    }
  }
}
