import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener, Inject,
  NgZone,
  OnDestroy,
  OnInit, PLATFORM_ID,
  ViewChild,
  effect,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { Language } from '@core/interface';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import UtilityFunctions from "@core/utilities/functions";
import {
  StoreService,
  MainDataService,
  AuthTokenService,
  CartService,
  LoaderService,
  TenantService,
  AppDataService,
  LanguageService, ProductLogicService, PermissionService, CommonService
} from "@core/services";
import {isPlatformBrowser} from "@angular/common";
import jwt_decode from "jwt-decode";
import {GaActionEnum , GoogleAnalyticsService} from "ngx-google-analytics";
import {GaLocalActionEnum} from "@core/enums/ga-local-action-enum";

declare let google: any;

@Component({
  selector: 'app-mtn-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('op', { static: false }) op: any;
  applyTo: any;
  isShowYello: boolean = environment.isStoreCloud
  subscription: Array<Subscription> = [];
  isActiveSearch: boolean = false;
  isfirstTime: boolean = false;
  isExpanded: boolean = false;
  products: Array<any> = [];
  authToken: any = '';
  logo: string = '/Images/logo.png';
  user: any;
  Name: string = '';
  baseUrl: string;
  languages: Array<Language> = new Array<Language>();
  search: string = '';
  selectedTenant: any;
  Lat!: number;
  Lng!: number;
  zoom!: number;
  address!: string;
  deliverTo!: any;
  countries: Array<any> = [];
  myplaceHolder: string | undefined;
  source: any;
  permission: any;
  isShop: boolean = false;
  selectedLanguage: any;
  screenWidth?: any;
  count: any;
  innerWidth: number;
  mobileScreen: boolean = false;
  scConf: boolean = false;
  desktopView: boolean;
  showDialog: boolean = false;
  isShowSearch : boolean = false;
  merchantURL: any = environment.merchantURL
  countriesList: any;
  allCountries: any = [];
  cartListCount: any = 0;
  cartListData: any = [];
  public country: string = '';
  private geoCoder: any;
  private _BaseURL = environment.apiEndPoint;
  defaultLanguage: Language;
  selectedCountry:string | any = 'EG';
  zoomLevelClass: string = 'default-zoom';
  navbarData: any;
  isMobileTemplate:boolean=false;
  isGoogleAnalytics:boolean=false;
  userDetails: any;
  constructor(
    private ngZone: NgZone,
    private store: StoreService,
    private router: Router,
    private languageService: LanguageService,
    private translate: TranslateService,
    private mainDataService: MainDataService,
    private cd: ChangeDetectorRef,
    private cartService: CartService,
    private authTokenService: AuthTokenService,
    private messageService: MessageService,
    private cookieService: CookieService,
    private loaderService: LoaderService,
    private tenantService: TenantService,
    private appDataService: AppDataService,
    private productLogicService: ProductLogicService,
    private permissionService: PermissionService,
    private dialogService:AppDataService,
    private commonService: CommonService,
    private $gaService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isMobileTemplate = this.permissionService.hasPermission('Mobile-Layout');
    this.isGoogleAnalytics = this.permissionService.hasPermission('Google Analytics');

    this.baseUrl = `${environment.apiEndPoint}`;
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.screen.width;
      if (this.screenWidth > 768) {
        this.desktopView = true;
      } else {
        this.desktopView = false;
      }
      effect(() => {
        this.showDialog = this.dialogService.dialogVisibility();
      });
    }

    if (environment.isStoreCloud) {
      this.scConf = true;
      this.fetchGoogleTId()
    } else {
      this.scConf = false;

    }
  }

  ngOnInit(): void {
    this.selectedCountry = localStorage.getItem('isoCode')
    this.innerWidth = window.innerWidth;

    this.selectedLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : this.defaultLanguage;
    this.mainDataService.getCartLengthData().subscribe((data: any) => {

      this.cartListCount = data ?? 0;
      if (this.cartListCount > 0) {

        if (this.cartListCount > 9) {
          this.cartListCount = '9+';
        }
      }
      this.cd.markForCheck();
      this.cd.detectChanges();
    });
    this.mainDataService.getUserData().subscribe((data: any) => {
      if (data) {
        let firstName = data.name.split(' ')[0];
        this.Name = firstName;
      }
      this.cd.markForCheck();
      this.cd.detectChanges();
    });
    this.commonService.isShowSearchBox.subscribe((res: boolean) => {
      if(!this.desktopView){
        if(!res){
          this.isExpanded = false;
        }
        this.isShowSearch = res;
      }
    })
    let user: any = this.store.get('profile');


    if (user) {
      this.mainDataService.setUserData(user)
    }
    if (this.innerWidth < 768) {
      this.mobileScreen = true;
    } else {
      this.mobileScreen = false;
    }
    this.getLanguages();
    this.getAllCountries();
    if(this.appDataService?.layoutTemplate){
      this.navbarData = this.appDataService.layoutTemplate.find((section: any) => section.type === 'navbar');
    }
    this.mainDataService.setBannerData(
      {
        isNavbarDataActive: this.navbarData?.isActive ?? false
      }
    )
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.authToken) {
      if (isPlatformBrowser(this.platformId)) {
        if(document.getElementById('profile')){
          const ele: any = document.getElementById('profile');
          if(ele){
            ele.click();
          }
          this.op.hide();
        }
        }

    }
  }

  searchCountries(event: any) {
    if (this.country.length >= 1) {
      const normalizedCountry = this.removeDiacritics(this.country.toLowerCase());
      this.countriesList = this.allCountries.filter((item: any) =>
        this.removeDiacritics(item.name.toLowerCase()).includes(normalizedCountry)
      );
    } else if (this.country.length === 0) {
      this.countriesList = this.allCountries;
    }
  }

  removeDiacritics(text: any) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  countryFlagImages(img: any) {
    return UtilityFunctions.verifyImageURL(img, this._BaseURL);

  }
  onCountrySelected(callingCode: any) {
    if (callingCode == '233') {
      return '2';
    } else if (callingCode == '+256') {
      return '1';
    } else if (callingCode == '225') {
      return '4';
    } else if (callingCode == '20') {
      return '3';
    }
    return '';
  }

  getMainData(): void {
    const initialData = this.appDataService.initialData
    this.isShop = initialData.isShop;
    this.store.set('isShop', this.isShop);
  }

  async SetTenant() {
    this.authToken = null;
    const localMaintenanceTenant = localStorage.getItem('maintenanceModeTenant')
    localStorage.clear();
    localStorage.setItem('maintenanceModeTenant', localMaintenanceTenant || '')
    await this.logOut();
    localStorage.setItem('tenantId', this.selectedTenant);
    localStorage.setItem('allCountryTenants', JSON.stringify(this.countries));
    this.store.set('cartProducts', []);
    localStorage.setItem('sessionId', '');
    this.store.set('SessionId', '');
    this.Name = '';
    this.resetStorage();
  }


  getAllCountries() {
    const tenants = this.appDataService.tenants
    if (tenants.records) {
      const countries = tenants.records;
      countries[0].selected = true;
      const tenantId = localStorage.getItem('tenantId');
      if (tenantId && tenantId !== '') {
        this.selectedTenant = countries.find((country: any) => country.tenantId == tenantId)
      }
      this.countries = countries;
      this.countriesList = this.countries;
      this.allCountries = this.countriesList;
    }
  }
  changeCountry(val:any){
    console.log(val);
    this.setTenant(val)
  }

  setupCountry() {
    let tenantId = localStorage.getItem('tenantId');
    this.isShop = localStorage.getItem('ShopId') != null;
    let permission = 'not-granted';
    if (window?.navigator?.geolocation) {
      navigator?.permissions.query({ name: 'geolocation' })
        .then((PermissionStatus: any) => {

          if (PermissionStatus.state == 'granted') {
            permission = 'granted';
          } else if (PermissionStatus.state == 'prompt') {
            permission = 'not-granted';
          } else {
            permission = 'denied';
          }

          if (permission === 'not-granted') {
            this.isfirstTime = true;
            this.setupCountryWithLocation();
          } else {
            this.setupCountryWithTenant(Number(tenantId));
          }
        },
          (error: any) => {

          });
    }
  }

  setupCountryWithTenant(tenantId: number) {
    let country = this.countries.find(
      (x: any) => x.tenantId === tenantId
    );
    if (!country) {
      country = this.countries[0];
    }
    country.selected = true;
    if (this.selectedTenant != tenantId) {
      this.selectedTenant = country.tenantId;
      localStorage.setItem('tenantId', country.tenantId);
      localStorage.setItem('allCountryTenants', JSON.stringify(this.countries));

      location.reload();
    } else {
      this.selectedTenant = country.tenantId;
      this.selectedTenant = country.tenantId;
      localStorage.setItem('tenantId', country.tenantId);
    }
    this.cd.detectChanges();
  }

  setupCountryWithLocation() {
    if (isPlatformBrowser(this.platformId)) {
      if (window?.navigator?.geolocation) {
        this.loaderService.show();
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            if ('geolocation' in navigator) {
              this.loaderService.show();
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  this.Lat = position.coords.latitude;
                  this.Lng = position.coords.longitude;
                  this.zoom = 8;
                  this.permission = true;


                  let latlng = new google.maps.LatLng(this.Lat, this.Lng);
                  this.googleMapAPiResponse(latlng)
                },
                (error: any) => {

                }, {
                  maximumAge: 60000,
                  timeout: 2000
                }
              );
            }
          },
          (error) => {
            const tenantId = localStorage.getItem('tenantId') ?? environment.defaultTenant;
            this.setupCountryWithTenant(Number(tenantId));
          }
        );
      }
    }
  }
  googleMapAPiResponse(latlng : any){
      this.geoCoder.geocode({ 'latLng': latlng }, (results: any, status: any) => {
      if (status == google.maps.GeocoderStatus.OK && results[0]) {
      let isoCountry = 'UG';
      results[0].address_components.forEach((obj: any) => {
      if (obj.types.includes('country')) isoCountry = obj.short_name;
    });

    let country = this.countries.find(
      (x: any) => x.isoCode === isoCountry
    );
    this.loaderService.hide();
    this.setupCountryWithTenant(country.tenantId ?? 0);
    }
    });
}
  getAllCart(): void {
    const cartData = {
      cartId: localStorage.getItem('cartId'),
      sessionId: localStorage.getItem('sessionId') ?? '',
      applyTo: this.applyTo
    };
    if (cartData.sessionId) {
      this.cartService.getCart(cartData)
        .subscribe({
          next: (res: any) => {
            this.cartListCount = 0;
            this.cartListData = [];
            if (res.data?.records?.length) {
              this.cartListCount = 0;
              if (res.data.records[0].cartDetails.length) {
                this.cartListCount = res.data.records[0].cartDetails.length;
                this.cartListData = res.data.records[0].cartDetails;

              }
              if (res.data.records[0].cartDetailsDPay && res.data.records[0].cartDetailsDPay.length) {
                this.cartListCount += res.data.records[0].cartDetailsDPay.length;
                this.cartListData = this.cartListData.concat(res.data.records[0].cartDetailsDPay)
              }
              this.mainDataService.setCartLenghtData(this.cartListCount);
              this.mainDataService.setCartItemsData(this.cartListData);

            } else {
              this.mainDataService.setCartLenghtData(0);
              this.mainDataService.setCartItemsData([]);
            }
            this.productLogicService.cartProductList = this.cartListData;
          }
        });
    } else {
      this.products = [];
    }

  }

  public compareCartProducts(products: [], storeProducts: []) {
    if (products.length) {

      products.forEach((item: any) => {
        storeProducts.forEach((data: any) => {
          if (item.specsProductId === data.specsProductId) {
            this.products.push(item);


          }
        });
      });
    } else {
      this.products = storeProducts;
    }

    localStorage.setItem('addedProducts', JSON.stringify(this.products));
  }

  getShipmentMethodByTenantId() {
    if (this.permissionService.hasPermission('Shipment-Fee')) {
      this.cartService.getShipmentMethodByTenantId().subscribe((res: any) => {
        if (res.success && res.data.length) {
          this.applyTo = res.data[0].applyTo
          localStorage.setItem('apply-to', this.applyTo);
          this.getAllCart();
        }
      })
    } else {
      localStorage.setItem('apply-to', '2');
      this.applyTo = 2;
      this.getAllCart();
    }

  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      let profile = localStorage.getItem('profile')
      if (profile && profile !== '') {
        this.getShipmentMethodByTenantId()
      } else {
        this.getAllCart()
      }
      this.authTokenService.authTokenData.subscribe(
        (message) => (this.authToken = message)
      );
      if (!this.authToken) {
        this.authToken = this.cookieService.get('authToken');
        if(!this.authToken) {
          let profile: any = localStorage.getItem('profile')
          if(profile && profile !== '') {
            profile = JSON.parse(profile)
            this.authToken = profile.authToken.replace('bearer ', '')
            const decoded: any = jwt_decode(this.authToken);

            let days: any = (decoded.exp / (60 * 60 * 24 * 1000)).toFixed(
              0
            );
            const dateNow = new Date();
            dateNow.setDate(dateNow.getDate() + parseInt(days));
            this.cookieService.set('authToken', this.authToken, {
              expires: dateNow,
              path: '/',
              sameSite: 'Strict',
            });
          }
        }
      }


      this.subscription[2] = this.store.subscription('cartProducts').subscribe({
        next: (res: any) => {
          if (res) {
            this.products = res;
            this.cd.detectChanges();

          }

        },
        error: (err: any) => {
          console.error(err);
        },
      });


      this.subscription[4] = this.store.subscription('mainData').subscribe({
        next: (res: any) => {
          let data = res?.find((obj: any) => obj.key.toLowerCase() === 'logo');
          if (data) this.logo = '/' + (data.displayName || this.logo);
        },
        error: (err: any) => {
          console.error(err);
        },
      });
    }, 1);
  }

  ngOnDestroy(): void {
    this.subscription.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  resetStorage() {


    localStorage.removeItem('decimalValue');
    localStorage.removeItem('countryPhone');
    localStorage.removeItem('PhoneLength');
    localStorage.removeItem('currency');
    localStorage.removeItem('customerAddressLandmarkRequired');
    if (isPlatformBrowser(this.platformId)) {
      window.location.reload();
    }
  }

  async logOut(): Promise<void> {

    const tenantId = localStorage.getItem('tenantId') || '1';
    const maintenanceMode = localStorage.getItem('maintenanceMode') ?? 'false'
    const saveCookie = localStorage.getItem('save_cookie') || '';
    const localMaintenanceTenant = localStorage.getItem('maintenanceModeTenant')
    const GATrackingId = localStorage.getItem('GATrackingId') || ''
    localStorage.clear();
    localStorage.setItem('maintenanceModeTenant', localMaintenanceTenant || '')
    this.authToken = null;

    sessionStorage.clear();
    this.authTokenService.authTokenSet('');


    this.cookieService.delete('authToken', '/');
    this.store.set('profile', '');
    this.mainDataService.setCartLenghtData(0);
    this.mainDataService.setCartItemsData([]);
    this.mainDataService.setUserData(null);
    localStorage.setItem('sessionId', '');
    localStorage.setItem('addedProducts', '');
    localStorage.setItem('cartId', '');
    this.store.set('cartProducts', []);
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('auth_enc');
    localStorage.setItem('tenantId', tenantId)
    localStorage.setItem('maintenanceMode', maintenanceMode)
    localStorage.setItem('save_cookie', saveCookie)
    localStorage.setItem('GATrackingId', GATrackingId)
    await this.router.navigate(['/']);

  }

  getLanguages() {
    this.languageService.getLanguage().subscribe((res) => {

      if (res?.languages) {
        let storedLang = localStorage.getItem('language');
        if (!storedLang) {
          let defaultLanguage = res.languages.find((x) => x.isDefault);
          if (!defaultLanguage && res.languages.length > 0) {
            this.defaultLanguage = res.languages[0];
          }
          if (defaultLanguage) {
            this.selectedLanguage = localStorage.getItem('lang') ? localStorage.getItem('lang') : '';
          }
        }
        this.languages = res.languages;
      }

    });
  }

  changeLang(lang: any): void {
    localStorage.setItem('language', lang);
    this.translate.use(lang);
  }


  setTenant(tenantId: any,country?:any) {
    let defaultLanguage: any = country.languages.filter((lang: any) => lang.isDefault)
    const tenantIdValue = localStorage.getItem('tenantId');
    if (tenantIdValue !== tenantId) {
      this.logOut();
      this.languageService.setCurrentLang(defaultLanguage[0].code)
      localStorage.setItem('tenantId',tenantId)
      this.router.navigate(['/'])
        .then(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.location.reload();
          }
        });
    } else {
      this.showDialog = false;
      this.dialogService.hideDialog();
    }
    this.userDetails = this.store.get('profile');
    if(this.isGoogleAnalytics){
    this.$gaService.event(GaLocalActionEnum.CLICK_ON_CHANGE_COUNTRY, '', 'SWITCH_COUNTRY', 1, true, {
      "country": country.name,
       "user_ID": this.userDetails ? this.userDetails.mobileNumber : 'Un_Authenticated',
       "ip_Address": this.store.get('userIP'),
        "device_Type": this.store.get('deviceInfo')?.deviceType,
        "device_Id": this.store.get('deviceInfo')?.deviceId,

    });
  }
  }

  signIn() {
    if(this.isGoogleAnalytics){
    this.$gaService.event(GaLocalActionEnum.CLICK_ON_SIGN_IN_UP, '', 'SIGNIN/SIGNUP', 1, true);
    }
    this.router.navigate(['/login']);
  }
  hideDialog() {
    this.dialogService.hideDialog();
    }
  toggleSearchBar() {
    this.isExpanded = !this.isExpanded;
    if(this.isExpanded){
      this.isShowSearch = true;
    }
  }
  getImage(imageLink: any) {
    if (imageLink) {
      return `${this.baseUrl}/Images/${imageLink}`;
    } else {
      return '';
    }
  }
  fetchGoogleTId() {
    this.mainDataService.getGoogleAnalyticsTrackingId().subscribe({
      next: (res: any) => {
        localStorage.setItem('GATrackingId', res.data?res.data[0].value:'');
      }, error: (err: any) => {
        console.error(err)
      }
    })
  }

  showAccountDetails() {
    if(this.isGoogleAnalytics){
    this.$gaService.event(GaLocalActionEnum.CLICK_ON_ACCOUNT_DETAILS, '', 'ACCOUNT_DETAILS', 1, true);
    }
    this.router.navigate(['/account'])
  }

  openCart() {
    this.userDetails = this.store.getFromLocalStorage('profile');
    if(this.isGoogleAnalytics){
    this.$gaService.event(
      GaLocalActionEnum.CLICK_ON_CART_ICON, 'navigation', 'CART_ON_TOP_BANNER', 1, true ,
      {
      "user_ID":this.userDetails?this.userDetails.mobileNumber:'Un_Authenticated',
      "ip_Address": this.store.get('userIP'),
      "device_Type": this.store.get('deviceInfo')?.deviceType,
      "device_Id": this.store.get('deviceInfo')?.deviceId,
      "session_ID": localStorage.getItem('sessionId')

    });
  }
    this.router.navigate(['/cart'])
  }

  openWishlist() {
    this.userDetails = this.store.getFromLocalStorage('profile');
    if(this.isGoogleAnalytics){
    this.$gaService.event(GaLocalActionEnum.CLICK_ON_WISHLIST_HEADER, '', '', 1, true , {
      "user_ID":this.userDetails?this.userDetails.mobileNumber:'Un_Authenticated'
    });
    }
    this.router.navigate(['/wishlist'])
  }

  openAccountAddress() {
    if(this.isGoogleAnalytics){
    this.$gaService.event(GaLocalActionEnum.CLICK_ON_ADDRESS, '', '', 1, true , {
      "user_ID":this.userDetails?this.userDetails.mobileNumber:'Un_Authenticated'
    });
  }
    this.router.navigate(['/account/address'])
  }
}
