<ng-container *ngIf="!isMobileTemplate" >
<div class="product-search p-inputgroup">

  <form autocomplete="off" style="display: contents">
    <input #inputField (focusout)="closeDropDown()"
           (keyup.enter)="searchProduct($event,'')" (ngModelChange)="searchChange($event)" [(ngModel)]="search"
           [ngModelOptions]="{ standalone: true }" autocapitalize="off" autocomplete="new-password" autocorrect="off"
           pInputText placeholder="{{
        scConfig
          ? ('search.scSearchOnMoMoMarket' | translate)
          : ('search.searchOnMoMoMarket' | translate)
      }}" spellcheck="false" type="text" />
    <span>
      <em (mousedown)="searchProduct($event,search)" class="pi pi-search"></em>
    </span>
  </form>

  <ng-container *ngIf="displayResult">
    <div class="search-results">
      <div class="my-3"></div>
      <div (mousedown)="NavigateToProduct(option.id)" *ngFor="let option of searchResult; index as i"
           class="px-2 padding-y-5" style="cursor: pointer">
        <div class="d-flex justify-content-between">
          <span class="medium-font font-size-14 large-name">{{ option.name }}</span>
          <span
            class="medium-font font-size-14">{{option.currencyCode}}&nbsp;{{option.specsProducts[0]?.salePrice?option.specsProducts[0]?.salePrice:option.specsProducts[0]?.price}}</span>
        </div>
        <div class="d-flex">
          <span class="prop-color">{{ "search.in" | translate }}</span>
          <span class="main-color font-size-14 mx-1" style="color: #004f71; font-weight: bold">{{ option.categoryName
            }}</span>
        </div>
        <p-divider></p-divider>
      </div>
      <p *ngIf="searchResult && searchResult.length === 0" class="ml-3">
        {{ "searchNotFound" | translate }}
      </p>
    </div>
  </ng-container>
</div>
</ng-container>
<ng-container *ngIf="isMobileTemplate" >
  <div class="product-search-mobile p-inputgroup">

    <form autocomplete="off" style="display: contents">
      <input #inputField (focusout)="closeDropDown()"
             (keyup.enter)="searchProduct($event,'')" (ngModelChange)="searchChange($event)" [(ngModel)]="search"
             [ngModelOptions]="{ standalone: true }" autocapitalize="off" autocomplete="new-password" autocorrect="off"
             pInputText placeholder="{{
        scConfig
          ? ('search.scSearchOnMoMoMarket' | translate)
          : ('search.searchOnMoMoMarket' | translate)
      }}" spellcheck="false" type="text" />
      <span>
      <em (mousedown)="searchProduct($event,search)" class="pi pi-search"></em>
    </span>
    </form>

    <ng-container *ngIf="displayResult">
      <div class="search-results">
        <div class="my-3"></div>
        <div (mousedown)="NavigateToProduct(option.id)" *ngFor="let option of searchResult; index as i"
             class="px-2 padding-y-5" style="cursor: pointer">
          <div class="d-flex justify-content-between">
            <span class="medium-font font-size-14 large-name">{{ option.name }}</span>
            <span
              class="medium-font font-size-14">{{option.currencyCode}}&nbsp;{{option.specsProducts[0]?.salePrice?option.specsProducts[0]?.salePrice:option.specsProducts[0]?.price}}</span>
          </div>
          <div class="d-flex">
            <span class="prop-color">{{ "search.in" | translate }}</span>
            <span class="main-color font-size-14 mx-1" style="color: #004f71; font-weight: bold">{{ option.categoryName
              }}</span>
          </div>
          <p-divider></p-divider>
        </div>
        <p *ngIf="searchResult && searchResult.length === 0" class="ml-3">
          {{ "searchNotFound" | translate }}
        </p>
      </div>
    </ng-container>
  </div>
</ng-container>
