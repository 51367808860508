import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare global {
    interface Window {
      dataLayer: any[];
    }
}

@Injectable({
 providedIn: 'root'
})

export class GTMService {

       injectGTM(tenants:any[],tenantId:any): void {
        // get google tag manager id by tenant
        const gtmId = tenants?.find((tenant:any)=>tenant.tenantId == tenantId)?.GTMConatinerId || '';
         // Inject the <script> tag in the <head> section
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    
        // Create the GTM dataLayer and the initialization script
        const inlineScript = document.createElement('script');
        inlineScript.text = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmId}');
        `;
    
        // Append the inline script and the external script to the <head>
        document.head.appendChild(inlineScript);
        document.head.appendChild(scriptTag);
    
        // Inject the <noscript> tag in the <body> section
        const noScriptTag = document.createElement('noscript');
        noScriptTag.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
                                 height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.body.appendChild(noScriptTag);
    }
    

    
   
     private pushDataLayer(obj:any){
        if(obj){
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(obj)
        }

    }
 
     pushPageView(category:string ,label?:string ,labelId?:any){
     if(category?.includes('->') ){
        category = category.split('->').join(' > ')
     }
      const pageViewURL = label ? category + ' > ' + label + (labelId ? + ' > ' + labelId : '') : category;
        const gtmTag = {
            event: 'Page View',
            event_label: pageViewURL
            };
            this.pushDataLayer(gtmTag);
    }
     pushEvent(event:string, category:string, action:string, label:string){
        const gtmTag = {
                event: event,
                category: category,
                action: action,
                label: label
            };
        this.pushDataLayer(gtmTag);
    }
}
 
