<div class="side-menu cursor-auto">
  <p-sidebar (onHide)="closeMenu()" [(visible)]="display" [baseZIndex]="8"
    [position]="lang === 'ar' ? 'right' : 'left'">

    <ng-template pTemplate="header">
<!--      <img alt="Logo" class="d-flex header-logo align-items-center justify-content-center" style="height: 101px;"-->
<!--        src="assets/icons/logo-marketplace.png" />-->
    </ng-template>

    <ng-template pTemplate="content">
      <div class="d-flex flex-column w-100 sidebar-categories">
        <ng-container *ngFor="let category of categories">
          <div (mouseover)="showSubCategory=true; subMenuCategories=category.categories" *ngIf="!category?.hide"
            class="d-inline-flex sidebar-categories__category">
            <span class="sidebar-categories__category__item" [ngStyle]="{
        'pointer-events':  !category.parentChildCount ? 'none' : ''}" [routerLink]="'/category/'+category.id" [queryParams]="{ categoryName:category.categoryName}"


              (click)="closeMenu(true, category.categoryName)">{{category.categoryName}}</span>
          </div>
        </ng-container>
      </div>

      <div *ngIf="showSubCategory && subMenuCategories.length > 0" class="sidebar-sub-category">
        <div class="d-flex flex-wrap" style="padding: 20px 0px">
          <div *ngFor="let category of subMenuCategories"
            class="d-inline-flex flex-column sidebar-sub-category__section">
            <h2 class="sidebar-sub-category__section__heading cursor-pointer"  *ngIf="!category?.hide"
                [routerLink]="'/category/'+category.id" [queryParams]="{ categoryName:category.categoryName}"
                [ngStyle]="{
        'pointer-events':  !category.parentChildCount ? 'none' : ''}"
              (click)="closeMenu(true, category.categoryName)">
              {{category.categoryName}}</h2>
            <div class="d-flex flex-column sidebar-sub-category__section__sub-name-section">

              <div *ngFor="let subCategory of category.categories; let i=index"
                class="d-inline-flex sidebar-sub-category__section__sub-name-section__sub-name">
                <ng-container>
                  <span (click)="closeMenu(true, subCategory.categoryName)"
                    class="sidebar-sub-category__section__sub-name-section__sub-name__item cursor-pointer"
                        *ngIf="!subCategory?.hide"
                        [ngStyle]="{
        'pointer-events':  !subCategory.parentChildCount ? 'none' : ''}"
                    [routerLink]="'/category/'+subCategory.id" [queryParams]="{ categoryName:category.categoryName}">
                    {{subCategory.categoryName}}
                  </span>
                </ng-container>
              </div>

              <div *ngIf="false" class="d-inline-flex sidebar-sub-category__section__sub-name-section__sub-name
             sidebar-sub-category__section__sub-name-section__sub-name__see-all justify-content-end cursor-pointer">
                <span class="underline">See all</span>
                <em class="pi pi-angle-right ml-1" style="color: #ffffff"></em>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-auto" *ngIf="tenantId != '3' && isShowAllMerchants">
        <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
        <a (click)="closeMenu(true)" routerLink="/merchants" pRipple
          class="flex align-items-center cursor-pointer p-3 gap-2 border-round text-0  transition-duration-150 transition-colors p-ripple">

          <span class="font-bold mobile-merchant">{{ "sideMenu.allMerchants" | translate }}</span>
        </a>
      </div>
    </ng-template>

  </p-sidebar>


  <em (click)="openMenu()" class="pi pi-bars pi-white cursor-pointer" style="display: flex; margin-top: 2px">
    <span class="ml-2 mr-1 pi-pi-text">
      <ng-content></ng-content>
    </span>
  </em>
</div>
