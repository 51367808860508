import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, catchError, map, Observable, share, shareReplay, tap } from "rxjs";
import { ResponseModel, RefreshTokenViewModel } from '../interface';
import {RegisterService, StoreService} from "./";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl: string;
  userController: string;
  otpController: string;
  url: string;
  ipAddress$: BehaviorSubject<any | null> = new BehaviorSubject(null);


  constructor(
    private http: HttpClient, private store: StoreService,private otpService:RegisterService
  ) {
    this.baseUrl = `${environment.apiEndPoint}/Auth`;
    this.url = `${environment.apiEndPoint}`
    this.userController = '/User';
    this.otpController = '/OtpUser';
  }

  login(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}${this.userController}/Login`, data);
  }
  sendOtp(data: any): Observable<object> {
    return this.http.post(`${this.baseUrl}${this.userController}/OtpLogin`, data);
  }
  refreshToken(data: RefreshTokenViewModel): Observable<ResponseModel<RefreshTokenViewModel>> {
    return this.http.post(`${this.baseUrl}${this.userController}/RefreshToken`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  addPrimaryOrSecondaryPhone(data: any){
    // let endPoint = localStorage.getItem('secondaryDefault')?'AddNewUserPhone':'UpdatePrimaryOrAddSecondaryNumber'
    if(this.otpService.toggleBoolean){
      return this.http.post(`${this.url}${this.userController}/AddNewUserPhone`, data)
    }else{
      return this.http.post(`${this.baseUrl}${this.userController}/UpdatePrimaryOrAddSecondaryNumber`, data)
    }
  }
  SetPrimaryPhoneNumber(id:any){
    return this.http.post(`${this.url}${this.userController}/SetPrimaryPhoneNumber`, id)
  }
  addSecondaryPhoneDefault(data: any){
    return this.http.post(`${this.baseUrl}${this.userController}/AddNewUserPhone`, data)
  }
  getPhoneNumbers(){
    return this.http.get(`${this.baseUrl}${this.userController}/GetCustomerPhoneNumbers`)
  }

  deletePhoneNumber(id: any){
    return this.http.post(`${this.baseUrl}${this.userController}/DeleteSecondaryPhoneNumber/${id}`,{})
  }
  getAllFeatureByTenant(): Observable<any> {
    return this.http.get(`${this.url}/Tenant/FeatureByTenant/GetAllFeatureByTenant`).pipe(map((res: any) => {
      return res;
    }));
  }

  isMarketplace(){
    return location.hostname === environment.marketPlaceHostName;
  }

  getShopSettingsData(){
    return this.http.get(`${environment.apiEndPoint}/Tenant/ShopSetting/GetAllSocialLinks`)
  }
  getUserIPAddress(){
    if (!this.ipAddress$.value) {
   this.http.get(`${environment.apiEndPoint}/User/GetUserIPAddress`).pipe(
    tap(ip => this.ipAddress$.next(ip)),
    catchError(error => {
      console.error('Error fetching IP Address', error);
      this.ipAddress$.next(null);
      return [];
    })
      ).subscribe();
  
    }
    return this.ipAddress$.asObservable();

     
  }

  // loginExternal(data: LoginExternal, token: string) {
  //   const headers = new HttpHeaders({
  //     'Authorization': `bearer ${token}`
  //   });
  //   return this.http.post(`${environment.apiEndPoint}/User/GetAuthTokenExternal`,data, {headers})
  // }

  // getAuthorizationToken(data: any) {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   });
  //   return this.http.post(`${environment.securityApiUrl}`, data, { headers })
  // }
  PromotionStockCheck(id: any){
    return this.http.get(`${environment.apiEndPoint}/Order/Order/CheckPromotionalStock/${id}`)
  }
}
