<div class="mtn-main-slider">
  <!--  show indicator is controlling using scss-->
    <p-carousel [value]="sliders" [circular]="true" [autoplayInterval]="3000" [showIndicators]="false" (onPage)="changeSliderDot($event)">
      <ng-template let-mainSlider pTemplate="item">
        <!-- <img
          alt="No Image"
          style="width: 100%;"
          height="298" [src]="getBannerImages(mainSlider.imageUrl)" (click)="routeToCTA(mainSlider,mainSlider.CTALink)"> -->

          <div class="banner-image" [ngStyle]="{'background-image': 'url('+ getBannerImages(mainSlider.imageUrl) +')'}"
          (click)="routeToCTA(mainSlider,mainSlider.CTALink)"
          ></div>
      </ng-template>
    </p-carousel>
  </div>
  <div class="row">
    <div class="center-dot mt-2">
      <div *ngFor="let dot of sliderDots" [ngClass]="dot.selected ? 'dot-color' : 'dot-simple'"></div>
    </div>
  </div>

