import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  Inject,
  PLATFORM_ID, Renderer2,
  ViewChild
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {firstValueFrom, Subscription, take} from "rxjs";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, Routes} from "@angular/router";
import {CookieService} from "ngx-cookie-service";

import {environment} from 'src/environments/environment';

import {
  AuthService,
  AuthTokenService, LoaderService,
  MainDataService,
  StoreService,
  TenantService,
  SitemapService,
  PermissionService, AppDataService
} from "@core/services";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {CookieModalComponent} from "@shared/modals/cookie-modal/cookie-modal.component";
import UtilityFunctions from "@core/utilities/functions";
import {isPlatformBrowser} from "@angular/common";
import {Meta} from '@angular/platform-browser';
import {HttpClient} from "@angular/common/http";
import jwt_decode from "jwt-decode";
import { GTMService } from '@core/services/gtm.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  @ViewChild('scroll', {read: ElementRef}) public scroll!: ElementRef<any>;
  screenWidth?: any;
  desktopView: boolean;
  showTenantSelection: any = [];
  tenantId: any;
  title: string;
  tenantSubscription: Subscription;
  showHeader: boolean = false;
  initializeApp: boolean = false;
  waitForTenantSubscription: boolean = false;
  token: any;

  footerDetails: any = {
    mainLogo: 'assets/icons/momo-marketplace-footer-logo.png',
    mainLogoMobile:'assets/icons/new-logo-marketplace-mobile.svg',
    copyRights: 'footer.copyRightsMobile',
    copyRightsMobile:"footer.copyRightsMobile",
    sideLogo: 'assets/icons/footer-momo-logo.svg',
    bottonLogo:'assets/icons/footer-momo-logo-mobile.svg',
    payments: ['assets/icons/Momo-pay-logo.svg',
      'assets/icons/Mastercard-logo.svg',
      'assets/icons/Visa-logo.svg'],
    socials:[
      {
        icon: 'assets/icons/facebook-mb-icon.svg',
        url: 'https://www.facebook.com/'
      },
      // {
      //   icon:'assets/icons/insta-mb-icon.svg',
      //   url: 'https://www.instagram.com/'
      // },
      // {
      //   icon:'assets/icons/linkedin-mb-icon.svg',
      //   url: 'https://www.linkedin.com/'
      // }
    ]

  }
  sliderConfig: any = {
    isBannerActive: false,
    isNavbarDataActive: false
  }
  maintenanceMode: any[] = [];
  isMobileView = window.innerWidth <= 768;
  isMobileTemplate: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    this.isMobileView = this.screenWidth <= 768;
  }
  constructor(private appTitle: Title,
              private tenantService: TenantService,
              private cd: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
              public router: Router,
              private cookieService: CookieService,
              private authTokenService: AuthTokenService,
              private store: StoreService,
              private mainDataService: MainDataService,
              private modalService: BsModalService,
              private permissionService: PermissionService,
              private authService: AuthService,
              @Inject(PLATFORM_ID) private platformId: any,
              private meta: Meta,
              private loaderService: LoaderService,
              private sitemapService: SitemapService,
              private appDataService: AppDataService,
              private renderer: Renderer2,
              private http: HttpClient,
              private $gtmService:GTMService) {
      if (isPlatformBrowser(this.platformId)) {
        this.screenWidth = window.screen.width;
        if (this.screenWidth >= 768) {
          this.desktopView = true;
        } else {
          this.desktopView = false;
        }
    }
    this.triggerMaintenanceMode()

    if (environment.isStoreCloud) {
      this.title = 'Yalla Mall';
      $('body').addClass('storecloud');
      localStorage.setItem('tenantId', environment.defaultTenant)
    } else {
      this.title = 'Market by MoMo';
      this.showTenantSelection.push(this.title);
    }

    appTitle.setTitle(this.title);
    this.tenantId = localStorage.getItem('tenantId')

  }

  ngOnInit() {
    this.meta.addTag({ name: 'title', content: 'Market by MoMo' });
    this.meta.addTag({ name: 'description', content: 'E-commerce platform' });

  }

  initializeApplication() {
    this.loaderService.show()
    this.updateApiExpiry();

    // TODO: Generate updated sitemaps from the following method
    // this.generateSitemap()

    const showCookiePopup = localStorage.getItem('save_cookie')
    if (!showCookiePopup) {
      this.triggerCookiePopup()
    }
    this.activatedRoute.queryParams.subscribe(queryParams => {

      if (queryParams.tenantId) {
        this.tenantId = queryParams.tenantId || environment.defaultTenant
        localStorage.setItem('tenantId', this.tenantId)
        this.waitForTenantSubscription = true
        let authToken: any = this.cookieService.get('authToken');
        if(!authToken) {
          let profile: any = localStorage.getItem('profile')
          if(profile && profile !== '') {
            profile = JSON.parse(profile)
            authToken = profile.authToken.replace('bearer ', '')
            const decoded: any = jwt_decode(authToken);

            let days: any = (decoded.exp / (60 * 60 * 24 * 1000)).toFixed(
              0
            );
            const dateNow = new Date();
            dateNow.setDate(dateNow.getDate() + parseInt(days));
            this.cookieService.set('authToken', authToken, {
              expires: dateNow,
              path: '/',
              sameSite: 'Strict',
            });
          }
        }
        if (!authToken) {
          this.logOut()
          this.loadAppData()
        } else {
          this.loadAppData()
        }
      } else {
        setTimeout(() => {
          if (!this.waitForTenantSubscription) {
            this.loadAppData()
          }
        }, 500)
      }

    });
    if (environment.isStoreCloud) {
      this.title = 'Yalla Mall';
      $('body').addClass('storecloud');
      let tenant = localStorage.getItem('tenantId');
      if(!tenant){
         localStorage.setItem('tenantId', environment.defaultTenant)
      }
    } else {
      this.title = 'Market by MoMo';
      this.showTenantSelection.push(this.title);
    }

    this.mainDataService.getBannerData().subscribe((data: any) => {
      if (data?.isBannerActive) {
        this.sliderConfig.isBannerActive = data.isBannerActive
      }
      if (data?.isNavbarDataActive) {
        this.sliderConfig.isNavbarDataActive = data.isNavbarDataActive
      }
      this.cd.detectChanges();
    })

    // this.tenantSubscription = this.tenantService.getTenant()
    //   .subscribe(response => {

    //     this.tenantId = response
    //     localStorage.setItem('tenantId', this.tenantId)
    //     this.loadAppData()
    //   })
      this.tenantId = localStorage.getItem('tenantId')
      if(this.tenantId){
        this.loadAppData()
      }

    this.tenantService.getHeader()
      .subscribe((resp: any) => {
        this.showHeader = resp
        this.cd.detectChanges()
      })

    // this.loadAppData()

    UtilityFunctions.assignSessionId();
    UtilityFunctions.assignConsentSessionId();
  }

  scrollToTop() {
    this.scroll.nativeElement.scrollTop = 0;
  }

  async loadAppData() {
    if (!this.authService.isMarketplace()) {
      await this.authService.getShopSettingsData().subscribe({
        next: (res: any) => {
          if(res.data) {
            this.appDataService.shopSettingData = res.data
            this.cd.detectChanges()
          }
        }
      })
    }
    if (this.tenantId !== null && this.tenantId !== '' && this.tenantId != undefined) {
      await this.tenantService.getAppConfigurationData(this.isMobileView);
      this.authService.getAllFeatureByTenant().subscribe({
        next: async (res: any) => {
          if (res.success) {
            const permissions = res.data.map((perm: any) => perm.portalName);
            this.permissionService.setPermissions(permissions);
            this.isMobileTemplate = this.permissionService.hasPermission('Mobile-Layout');

            await this.tenantService.getConfigurationWithoutCache(this.isMobileView && this.permissionService.hasPermission('Mobile-Layout'))

            if (this.permissionService.hasPermission('Google Analytics')) this.getTagsData()
            this.initializeApp = true

            this.loaderService.hide()

            this.cd.detectChanges()
          }
        },
        error: (err: any) => {
          this.initializeApp = true;
        }
      });
      // await this.getAllowedFeatures();

    }
  }
getTagsData(){
    this.mainDataService.getAllGoogleTagConfigurations().subscribe({
      next: (res: any) => {
        const permissions = res.data.map((perm: any) => {
          if (perm.isActive) {
            return perm.tag;
          }
        });
        this.permissionService.setTagFeature(permissions);
      }, error: (err: any) => {
        console.error(err)
      }
    })
}
  triggerCookiePopup() {
    if (isPlatformBrowser(this.platformId)) {
      const cookieModalData: ModalOptions = {
        initialState: {
          cookieModalDetails: {
            header: "We Value your privacy",
            message: 'We use cookies to ensure you get the best experience on our website. By using our website you agree to your cookie.'
          }
        },
        backdrop: 'static',
        ignoreBackdropClick: true,
        class: "cookie-popup-width"
      };
      const bsModalRefCookie: BsModalRef = this.modalService.show(CookieModalComponent, cookieModalData);
      bsModalRefCookie.content.submit.pipe(take(1)).subscribe((value: boolean) => {
      });
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.tenantSubscription.unsubscribe();
    }
  }

  logOut() {
    sessionStorage.clear();
    this.authTokenService.authTokenSet('');

    this.cookieService.delete('authToken', '/');
    this.store.set('profile', '');
    this.mainDataService.setCartLenghtData(null);
    this.mainDataService.setUserData(null);
    localStorage.setItem('secondaryDefault', 'false')
    localStorage.setItem('sessionId', '');
    localStorage.setItem('addedProducts', '');
    localStorage.setItem('cartId', '');
    this.store.set('cartProducts', []);
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('auth_enc');
  }

  async getAllowedFeatures(): Promise<any> {
    this.authService.getAllFeatureByTenant().subscribe({
      next: (res: any) => {
        if (res.success) {
          const permissions = res.data.map((perm: any) => perm.portalName);
          this.permissionService.setPermissions(permissions);
          this.isMobileTemplate = this.permissionService.hasPermission('Mobile-Layout');
        }
      },
      error: (err: any) => {
        this.initializeApp = true;
      }
    });
  }

  updateApiExpiry() {
    const apiExpiry = localStorage.getItem('apiExpiry')
    if (!apiExpiry && apiExpiry === '') {
      localStorage.setItem('apiExpiry', new Date().getTime().toString())
    }
  }

  generateSitemap() {
    const allRoutes: Routes = this.router.config;
    const relevantRoutes = this.filterRelevantRoutes(allRoutes); // Optionally filter out irrelevant routes
    const sitemapContent = this.sitemapService.generateSitemap(relevantRoutes);
  }

  filterRelevantRoutes(routes: any) {
    if (routes && routes.length > 0 && routes[0].children)
      return routes[0].children.filter((route: any) => {
        return !route.path.startsWith('category/') &&
          !route.path.startsWith('product/') &&
          !route.path.startsWith('order/') &&
          !route.path.startsWith('**');
      });
  }
  isSpecificRoute(): boolean {
    const specificRoutePath = ['/categories-list','/cart','/account','/wishlist','/checkout','/account/address','/account/verify-address','/account/address/add-address','/account/help'];
    const currentRoute = this.router.url;
    // console.log(currentRoute , specificRoutePath);
    return specificRoutePath.some((route:string)=>route == currentRoute);
  }

  isShowMaintenance(): boolean {
    let tenantId = Number(localStorage.getItem('tenantId'));
    return this.maintenanceMode.some((item: any) => {
      if (item.tenantId === tenantId) {
        return item.maintenanceMode
      }
    })
  }

  triggerMaintenanceMode() {
    const localMaintenanceTenant = localStorage.getItem('maintenanceModeTenant')
    if (localMaintenanceTenant && localMaintenanceTenant !== '') {
      this.maintenanceMode = JSON.parse(localMaintenanceTenant)
      setTimeout(() => {
        if (!this.isShowMaintenance()) {
          this.initializeApplication()
        }
        this.cd.detectChanges()
      }, 500)
    } else {
      this.http.get<any>('assets/data.json').subscribe((data: any) => {
        this.$gtmService.injectGTM(data,this.tenantId)
        this.maintenanceMode = data;
        if (!this.isShowMaintenance()) {
          this.initializeApplication()
        }
        this.cd.detectChanges()
      });
    }
  }

}
