// src/app/services/init.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { MainDataService } from './main-data.service';

@Injectable({
  providedIn: 'root',
})
export class InitService {
    // private initializationComplete = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private cookieService: CookieService,private mainDataService:MainDataService) {}

  async getFullSecurityApiKey(): Promise<any> {
    const data =
    `grant_type=client_credentials&client_id=${environment.client_id}&client_secret=${environment.client_secret}&scope=${environment.scope}`;
    const response = await fetch(environment.securityApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok' + response.statusText);
    }
    const responseData = await response.json();
    let decodedToken: any = jwt_decode(responseData.access_token);
    const expires = new Date(decodedToken.exp * 1000);
    expires.setTime(expires.getTime() + 5 * 60 * 60 * 1000);
    // this.cookieService.set('accessToken', decodedToken.access_token, {
    //   expires: expires,
    //   path: '/',
    //   sameSite: 'Strict',
    // });
    return await {access_token:responseData.access_token,expires:expires};
  }

  async getPublicApis() {
    const token = this.cookieService.get('accessToken')?? '';
    const response = await fetch(
      'https://uat-apps.paysky.io/sc-authenticator' + '/TenantConfiguration/GetPublicApis',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    const responseData = await response.json();
    return responseData;
  }

  loadScript(apiKey: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      const node = document.createElement('script');
      node.src = url;
      node.type = 'text/javascript';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
      node.onload = () => resolve();
      node.onerror = () => reject(new Error('Failed to load script'));
    });
  }

  shouldGetGoogleKey(mapKeyExpiry: number): boolean {
    const date1 = new Date(mapKeyExpiry);
    const date2 = new Date();
    const time1 = date1.getTime();
    const time2 = date2.getTime();
    const differenceInMinutes = (time2 - time1) / (1000 * 60);
    return differenceInMinutes >= 30;
  }

  async fetchGoogleKey(tenantId: string) {
    const response = await fetch(
      environment.apiEndPoint + '/Auth/TenantConfiguration/GetGoogleKey',
      {
        method: 'GET',
        headers: {
          tenantId:
            tenantId && tenantId !== '' ? tenantId : environment.defaultTenant,
        },
      }
    );
    const resp = await response.json();
    const apiKey = atob(resp.data.key);
    localStorage.setItem('mapKey', apiKey);
    localStorage.setItem('mapKeyExpiry', new Date().getTime().toString());
    await this.loadScript(apiKey);
  }
  doesCookieExist(cookieName: string): boolean {
    return this.cookieService.check(cookieName);
  }
  isTokenExpired(): boolean {
    let token = this.cookieService.get('accessToken')?? '';
    if (token=='') {
      return false;
    }
    const decodedToken: any = jwt_decode(token);
    const expiryTime = decodedToken.exp * 1000;
    const currentTime = new Date().getTime();
    return expiryTime - currentTime < 5 * 60 * 1000; // Refresh if token expires in less than 5 minutes
  }
  fetchGoogleTId() {
    this.mainDataService.getGoogleAnalyticsTrackingId().subscribe({
      next: (res: any) => {
        localStorage.setItem('GATrackingId', res.data?res.data[0].value:'');
      }, error: (err: any) => {
        console.error(err)
      }
    })
  }
  async initialize(): Promise<void> {
    const tenantId = localStorage.getItem('tenantId') || environment.defaultTenant;
    if(!tenantId || tenantId === '') localStorage.setItem('tenantId', tenantId)
    if(environment.client_id) {
      if (!this.doesCookieExist('accessToken')) {
        let result = await this.getFullSecurityApiKey();
        this.cookieService.set('accessToken', result.access_token, {
          expires: result.expires,
          path: '/',
          sameSite: 'Strict',
        });
        let openApis =await this.getPublicApis();
        localStorage.setItem('publicApis',JSON.stringify(openApis))
      }
    }
    this.fetchGoogleTId();


    const mapKeyExpiry = localStorage.getItem('mapKeyExpiry');

    if (mapKeyExpiry && this.shouldGetGoogleKey(parseInt(mapKeyExpiry))) {
      await this.fetchGoogleKey(tenantId);
    } else {
      const mapKey = localStorage.getItem('mapKey');
      if (mapKey) {
        await this.loadScript(mapKey);
      } else {
        await this.fetchGoogleKey(tenantId);
      }
    }
    // this.initializationComplete.next(true);

  }
//   getInitializationComplete$() {
//     return this.initializationComplete.asObservable();
//   }
}
