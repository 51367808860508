import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SitemapService {

  constructor() { }

  generateSitemap(routes: Routes): string {
    let sitemap = '<?xml version="1.0" encoding="UTF-8"?>';
    sitemap += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">';

    routes.forEach(route => {
      sitemap += `<url><loc>https://momomarket.africa:1010/${route.path}</loc></url>`;
    });

    sitemap += '</urlset>';
    return sitemap;
  }
}
