<section class="confirm-dialog">
    <div class="close-btn" (click)="closeDialog()">
    <button>
        <img class="w-20" src="assets/icons/close.svg" alt="No Image" />
    </button>
   </div>
    <div class="d-flex flex-column px-4">
        <img class="success-avatar" src="assets/images/success-avatar.svg"/>

    <p class="subtitle">{{successMsg | translate}}</p>
    <button pButton type="button" class="ok-btn second-btn" (click)="closeDialog()"
    label="{{ 'auth.registerPassword.okButton' | translate }}"></button>
    </div>
</section>
